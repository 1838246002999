import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Snackbar } from 'elentari'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import {
  caminhaoApi,
  clienteApi,
  destinoApi,
  logReimpressaoApi,
  origemApi,
  produtoApi
} from '../../services/Api'
import { reimprimirTicket } from '../../utils/impressora'
import LogReimpressaoCard from '../LogReimpressao/LogReimpressaoCard'
import FormAlterarDialog from './FormAlterarDialog'
import FormDialog from './FormDialog'
import { formatOpcaoPagamento } from './utils/formatOpcaoPagamento'

const styles = theme => ({
  root: {
    padding: 24
  },
  button: {
    marginTop: 8,
    marginBottom: 8
  },
  cancelarBtn: {
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  alterarBtn: {
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: '#f5c5b6',
    '&:hover': {
      backgroundColor: '#efa18a'
    }
  },
  btnImagemFrontal: {
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: '#f2b3a0',
    '&:hover': {
      backgroundColor: '#f2b3a8'
    }
  },
  btnImagemLateral: {
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: '#7FD4D3',
    '&:hover': {
      backgroundColor: '#62C0BF'
    }
  },
  log: {
    marginTop: 24
  }
})

const CustomButton = ({ loading, children, ...rest }) => (
  <Button {...rest}>
    {loading ? (
      <CircularProgress style={{ color: 'white' }} size={26} />
    ) : (
      children
    )}
  </Button>
)

function CaminhaoCard ({ classes, ...props }) {
  const { realm } = JSON.parse(localStorage.user)
  const [item, setItem] = useState()
  const [mensagem, setMensagem] = useState()
  const [saidaEspecialOpen, setSaidaEspecialOpen] = useState(false)
  const [cancelarOpen, setCancelarOpen] = useState(false)
  const [alterarOpen, setAlterarOpen] = useState(false)
  const [fetchingReimprimir, setfetchingReimprimir] = useState(false)

  const getCurrentId = params => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  async function fetchData (id) {
    const include = ['fila', 'cliente', 'regraFila', 'usuarioCancelamento']
    const response = await caminhaoApi.getOne(id, { include })
    if (response.ok) {
      const caminhao = response.data

      const [
        responseDestino,
        responseProduto,
        responseOrigem
      ] = await Promise.all([
        destinoApi.getOne(caminhao.regraFila.id_destino),
        produtoApi.getOne(caminhao.regraFila.id_produto),
        origemApi.getOne(caminhao.regraFila.id_origem)
      ])

      if (responseDestino.ok && responseProduto.ok && responseOrigem.ok) {
        caminhao.destino = responseDestino.data
        caminhao.produto = responseProduto.data
        caminhao.origem = responseOrigem.data
      }

      caminhao.opcaoPagamentoFormatted = formatOpcaoPagamento(
        caminhao.opcao_pagamento
      )

      setItem(caminhao)
    }
  }

  useEffect(() => {
    fetchData(getCurrentId(props.match.params))
  }, [props.history.location.pathname, props.match.params])

  const formatData = data => {
    return moment(data).format('DD/MM/YYYY - HH:mm:ss')
  }

  const handleSnackbarClose = () => {
    setMensagem('')
  }

  const onClickVisualizarImagem = url => {
    if (!url) {
      setMensagem('Não existe imagem para mostrar!')
    }
  }

  const getValorEixo = async () => {
    const resultValor = await clienteApi.getOne(item.id_cliente, {
      include: 'tabelaPreco'
    })

    if (resultValor.ok) {
      const precoEixo = `valor_${item.numero_eixo}_eixo`

      const valorReal = resultValor.data.tabelaPreco[precoEixo]

      return valorReal
    }

    return null
  }

  const handleReimprimir = async () => {
    const responseApi = await logReimpressaoApi.create({
      data: new Date(),
      id_caminhao: item.id
    })

    if (responseApi.ok) {
      const valorReal = await getValorEixo()
      if (!valorReal) {
        setMensagem('Não foi possível verificar o valor do eixo!')
        return
      }
      const data = {
        data_entrada: item.data_entrada,
        placa: item.placa,
        numero_eixo: item.numero_eixo,
        produto: item.produto.descricao,
        fila: item.fila.numero,
        nota: item.nfe,
        valor: valorReal,
        senha: item.senha
      }

      setfetchingReimprimir(true)
      const response = await reimprimirTicket(data)
      setfetchingReimprimir(false)
      if (!response.ok) {
        setMensagem('Não foi possível se comunicar com a impressora!')
      }
    } else {
      setMensagem('Falha ao tentar reimprimir!')
    }
  }

  const handleSaidaEspecial = () => {
    setSaidaEspecialOpen(true)
  }

  const handleCancelarSenha = () => {
    setCancelarOpen(true)
  }

  const handleAlterarFila = () => {
    setAlterarOpen(true)
  }

  const submitCancelarSenha = async data => {
    const body = { observacao: data.observacao }
    const response = await caminhaoApi.cancelarSenha(item.id, body)
    if (response.ok) {
      fetchData(getCurrentId(props.match.params))
    } else {
      setMensagem(response.data.error.message)
    }
  }

  const submitAlterarFila = async data => {
    const body = { id_fila: data.fila }
    const response = await caminhaoApi.alterarFila(item.id, body)
    if (response.ok) {
      setMensagem('Fila Alterada com sucesso!')
      fetchData(getCurrentId(props.match.params))
    } else {
      setMensagem(response.data.error.message)
    }
  }

  const submitSaidaEspecial = async data => {
    const body = { observacao: data.observacao }
    const response = await caminhaoApi.saidaEspecial(item.id, body)
    if (response.ok) {
      fetchData(getCurrentId(props.match.params))
    }
  }

  return item ? (
    <Fragment>
      <Paper className={classes.root}>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='center'
        >
          <Grid item sm={9}>
            <Typography variant='h5' gutterBottom>
              Dados do cadastro
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Data Entrada: </strong> {formatData(item.data_entrada)}
            </Typography>
            {item.data_chamada && (
              <Typography variant='body1' gutterBottom>
                <strong>Data Chamada: </strong> {formatData(item.data_chamada)}
              </Typography>
            )}
            {item.data_cancelamento && (
              <Typography variant='body1' gutterBottom>
                <strong>Data Cancelamento: </strong>{' '}
                {formatData(item.data_cancelamento)} -{' '}
                {item.usuarioCancelamento.username}
              </Typography>
            )}
            {item.data_saida && (
              <Typography variant='body1' gutterBottom>
                <strong>Data Saída: </strong> {formatData(item.data_saida)}
              </Typography>
            )}
            <Typography variant='body1' gutterBottom>
              <strong>Placa: </strong> {item.placa}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Eixos: </strong> {item.numero_eixo}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Origem: </strong> {item.origem.descricao}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Destino: </strong> {item.destino.descricao}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Produto: </strong> {item.produto.descricao}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Cliente: </strong> {item.cliente.nome}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>NFe: </strong> {item.nfe}
            </Typography>
            <Typography
              variant='body1'
              gutterBottom
              style={{ wordBreak: 'break-word' }}
            >
              <strong>NFe Chave: </strong> {item.nfe_chave}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Senha: </strong> {item.senha}{' '}
              {item.data_cancelamento && '- CANCELADA'}
            </Typography>
            {item.id_fila && (
              <Typography variant='body1' gutterBottom>
                <strong>Fila: </strong> {item.fila.numero}
              </Typography>
            )}
            {item.observacao && (
              <Typography variant='body1' gutterBottom>
                <strong>Observação: </strong> {item.observacao}
              </Typography>
            )}

            <Typography variant='body1' gutterBottom>
              <strong>Forma de Pagamento: </strong>{' '}
              {item.opcaoPagamentoFormatted}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              color='primary'
              target='_blank'
              variant='contained'
              href={item.url_foto_frontal_caminhao}
              onClick={() =>
                onClickVisualizarImagem(item.url_foto_frontal_caminhao)
              }
              className={classes.btnImagemFrontal}
            >
              Visualizar imagem Frontal
            </Button>
            <Button
              fullWidth
              color='primary'
              target='_blank'
              variant='contained'
              href={item.url_foto_lateral_caminhao}
              onClick={() =>
                onClickVisualizarImagem(item.url_foto_lateral_caminhao)
              }
              className={classes.btnImagemLateral}
            >
              Visualizar imagem Lateral
            </Button>
            <div
              hidden={
                (realm !== 'admin' &&
                  realm !== 'gestor' &&
                  realm !== 'operador') ||
                item.data_saida
              }
            >
              <CustomButton
                fullWidth
                color='primary'
                variant='contained'
                onClick={() => handleReimprimir()}
                className={classes.button}
                loading={fetchingReimprimir}
              >
                Reimprimir Senha
              </CustomButton>
            </div>
            <div hidden={item.data_saida}>
              <Button
                fullWidth
                color='secondary'
                variant='contained'
                onClick={() => handleSaidaEspecial()}
                className={classes.button}
              >
                Saída Especial
              </Button>
              <div hidden={realm !== 'admin' && realm !== 'gestor'}>
                <Button
                  fullWidth
                  color='inherit'
                  variant='contained'
                  onClick={() => handleCancelarSenha()}
                  className={classes.cancelarBtn}
                >
                  Cancelar senha
                </Button>
              </div>
              <Button
                fullWidth
                color='inherit'
                variant='contained'
                onClick={() => handleAlterarFila()}
                className={classes.alterarBtn}
              >
                Alterar Fila
              </Button>
            </div>
            <Button
              fullWidth
              variant='outlined'
              onClick={() => props.history.goBack()}
              className={classes.button}
            >
              Voltar
            </Button>
          </Grid>

          <FormDialog
            open={saidaEspecialOpen}
            primaryAction={submitSaidaEspecial}
            title='Saída Especial'
            handleClose={() => {
              setSaidaEspecialOpen(false)
            }}
          />
          <FormDialog
            open={cancelarOpen}
            primaryAction={submitCancelarSenha}
            title='Cancelar Senha'
            formRequired
            handleClose={() => {
              setCancelarOpen(false)
            }}
          />
          <FormAlterarDialog
            open={alterarOpen}
            caminhao={item}
            primaryAction={submitAlterarFila}
            title='Alterar Caminhão de Fila'
            handleClose={() => {
              setAlterarOpen(false)
            }}
          />
          <Snackbar
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={mensagem}
          />
        </Grid>
      </Paper>
      <div className={classes.log}>
        <LogReimpressaoCard
          id_caminhao={item.id}
          refetch={fetchingReimprimir}
        />
      </div>
    </Fragment>
  ) : null
}

export default withStyles(styles)(CaminhaoCard)

import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import { caixaApi } from '../../services/Api'
import { Button } from '@material-ui/core'

export default function CaixaRedirect ({ ...props }) {
  const [caixa, setCaixa] = useState()
  const [status, setStatus] = useState()

  const fetch = async () => {
    const result = await caixaApi.findOne({
      filter: {
        where: {
          data_fechamento: { eq: null }
        }
      }
    })
    if (result.ok) {
      setCaixa(result.data)
      setStatus(200)
    }
    if (result.status === 404) {
      setStatus(404)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  if (status) {
    return status === 200 ? (
      <Redirect to={`/caixas/${caixa.id}`} />
    ) : (
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={() => props.history.push('caixas/new')}
      >
        Abrir Caixa
      </Button>
    )
  }
  return null
}

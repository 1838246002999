import React, { useEffect, useState } from 'react'
import {
  LocationCity,
  TripOrigin,
  Store,
  Work,
  AttachMoney,
  SupervisorAccount,
  Person,
  FormatListNumbered,
  Label,
  Dashboard,
  LocalShipping,
  LocalParking,
  SwapHoriz,
  RecordVoiceOver,
  LocalAtm,
  ContactMail,
  Exposure
} from '@material-ui/icons'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RouteACL } from './Route'
import HomeIcon from '@material-ui/icons/Home'
import { AppWrap } from 'elentari'
import { ProdutosList, ProdutoForm } from '../Produtos'
import { OrigensList, OrigemForm } from '../Origens'
import { TransportadorasList, TransportadoraForm } from '../Transportadoras'
import { ClientesList, ClienteForm } from '../Clientes'
import { DestinosList, DestinoForm } from '../Destinos'
import { UsuariosList } from '../Usuarios'
import { CaixasList, CaixaCard, CaixaRedirect } from '../Caixas'
import { FilasList, FilaForm } from '../Filas'
import { RegrasFilaList, RegraFilaForm } from '../RegrasFila'
import { PatiosList, PatioForm, PainelPatios } from '../Patios'
import { ChaminhoesChamada, ListaChamada } from '../Chamada'
import { CaminhoesList, CaminhaoCard } from '../Caminhoes'
import {
  CaminhaoEspecialList,
  CaminhaoEspecialForm
} from '../CaminhoesEspeciais'
import { TiposFreteList, TipoFreteForm } from '../TiposFrete'
import { Financeiro, Patio } from '../Paineis'
import OperacoesCaixaForm from '../OperacoesCaixa/OperacoesCaixaForm'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { ErrorUnauthorized } from './errorUnauthorized'
import logo from '../../images/logoMenu.svg'
import moment from 'moment'
import { ContatoForm, ContatosList } from '../Contatos'
import OfflineAlert from './OfflineAlert'
import { TabelaPrecoList, TabelaPrecoForm } from '../TabelaPrecos'

const styles = theme => ({
  logo: {
    maxWidth: '100%'
  },
  logoOpen: {
    width: 210,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  logoClose: {
    width: 40,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
})

const AppWrapStyle = makeStyles(theme => ({
  drawerHeader: {
    backgroundColor: theme.palette.common.white,
    textAlign: 'center'
  }
}))

const Home = ({ classes, ...props }) => {
  const [open, setOpen] = useState(true)

  const onToggleDrawer = status => {
    setOpen(status === 'open')
  }

  const { realm } = JSON.parse(localStorage.user)
  const menuItems = [
    {
      icon: HomeIcon,
      pathname: `/`,
      roles: ['destino', 'operador', 'gestor', 'admin'],
      label: 'Home'
    },
    {
      icon: TripOrigin,
      pathname: '/origens',
      roles: ['gestor', 'admin'],
      label: 'Origens'
    },
    {
      icon: LocationCity,
      pathname: '/destinos',
      roles: ['gestor', 'admin'],
      label: 'Destinos'
    },
    {
      icon: Exposure,
      pathname: '/tabelas-precos',
      roles: ['gestor', 'admin'],
      label: 'Tabelas de Preços'
    },
    {
      icon: Person,
      pathname: '/clientes',
      roles: ['gestor', 'admin'],
      label: 'Clientes'
    },
    {
      icon: Store,
      pathname: '/produtos',
      roles: ['gestor', 'admin'],
      label: 'Produtos'
    },
    {
      icon: SwapHoriz,
      pathname: '/tipos-frete',
      roles: ['gestor', 'admin'],
      label: 'Tipos de Frete'
    },
    {
      icon: Work,
      pathname: '/transportadoras',
      roles: ['gestor', 'admin'],
      label: 'Transportadoras'
    },
    {
      icon: Label,
      pathname: '/regras-fila',
      roles: ['gestor', 'admin'],
      label: 'Regras de Fila'
    },
    {
      icon: LocalParking,
      pathname: '/patios',
      roles: ['gestor', 'admin'],
      label: 'Pátios'
    },
    {
      icon: AttachMoney,
      group: true,
      roles: ['operador', 'gestor', 'admin'],
      pathname: '/caixas',
      label: 'Caixas',
      items: [
        {
          icon: ArrowForwardIcon,
          pathname: '/caixa-atual',
          roles: ['operador', 'gestor', 'admin'],
          label: 'Atual'
        }
      ]
    },
    {
      icon: LocalShipping,
      pathname: '/caminhoes',
      roles: ['operador', 'gestor', 'admin', 'usuario'],
      label: 'Caminhões'
    },
    {
      icon: RecordVoiceOver,
      pathname: '/chamada',
      roles: ['operador', 'gestor', 'admin'],
      label: 'Chamada'
    },
    {
      icon: FormatListNumbered,
      group: true,
      roles: ['operador', 'gestor', 'admin'],
      pathname: '/filas',
      label: 'Filas',
      items: [
        {
          icon: FormatAlignJustifyIcon,
          pathname: '/filas',
          label: 'Filas Padrões',
          roles: ['operador', 'gestor', 'admin']
        },
        {
          icon: DragHandleIcon,
          pathname: '/caminhoes-irregulares',
          label: 'Caminhões Irregulares',
          roles: ['operador', 'gestor', 'admin']
        }
      ]
    },
    {
      icon: Dashboard,
      group: true,
      roles: ['operador', 'gestor', 'admin'],
      pathname: '/paineis',
      label: 'Painéis',
      items: [
        {
          icon: LocalParking,
          pathname: '/paineis-patio',
          roles: ['operador', 'gestor', 'admin'],
          label: 'Pátio'
        }
      ]
    },
    {
      icon: SupervisorAccount,
      pathname: '/usuarios',
      roles: ['admin'],
      label: 'Usuários'
    },
    {
      icon: ContactMail,
      pathname: '/contatos',
      roles: ['admin'],
      label: 'Contatos'
    }
  ]
  if (realm === 'gestor' || realm === 'admin') {
    menuItems
      .find(i => i.label === 'Painéis')
      .items.push({
        icon: LocalAtm,
        pathname: `/paineis-financeiro?filters=visualizacao=diario,dia=${moment().format(
          'YYYY-MM-DD'
        )}`,
        roles: ['gestor', 'admin'],
        label: 'Financeiro'
      })
    menuItems
      .find(i => i.label === 'Caixas')
      .items.push({
        icon: FormatAlignJustifyIcon,
        pathname: '/caixas',
        roles: ['operador', 'gestor', 'admin'],
        label: 'Histórico'
      })
  }
  const userMenu = menuItems.filter(menuItem => menuItem.roles.includes(realm))
  const pathReadableMap = {
    unauthorized: 'Não Autorizado',
    'operacoes-caixa': 'Operações de Caixa',
    'paineis-financeiro': 'Painéis Financeiro',
    'paineis-patio': 'Painéis Pátio',
    'caixa-atual': 'Caixa Atual'
  }

  return (
    <AppWrap
      menuItems={userMenu}
      onToggleDrawer={onToggleDrawer}
      pathReadableMap={pathReadableMap}
      userAvatarProps={{ label: 'Olá,', action: 'Sair' }}
      logo={
        <div className={open ? classes.logoOpen : classes.logoClose}>
          <img alt='logo' src={logo} className={classes.logo} />
        </div>
      }
      classes={AppWrapStyle()}
      theme='light'
    >
      <>
        <Switch>
          {realm === 'usuario' ? (
            <Route exact path='/' render={() => <Redirect to='/caminhoes' />} />
          ) : null}
          <Route path='/unauthorized' exact component={ErrorUnauthorized} />
          <RouteACL
            path='/'
            exact
            component={PainelPatios}
            menuItems={menuItems}
          />
          <RouteACL
            path='/produtos'
            exact
            component={ProdutosList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/chamada'
            exact
            component={ChaminhoesChamada}
            menuItems={menuItems}
          />
          <RouteACL
            path='/chamada/iniciar'
            exact
            component={ListaChamada}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caminhoes'
            exact
            component={CaminhoesList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caminhoes/:id'
            exact
            component={CaminhaoCard}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caminhoes-irregulares'
            exact
            component={CaminhaoEspecialList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caminhoes-irregulares/:id'
            exact
            component={CaminhaoEspecialForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/produtos/:id'
            exact
            component={ProdutoForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/origens'
            exact
            component={OrigensList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/origens/:id'
            exact
            component={OrigemForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/transportadoras'
            exact
            component={TransportadorasList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/transportadoras/:id'
            exact
            component={TransportadoraForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/destinos'
            exact
            component={DestinosList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/destinos/:id'
            exact
            component={DestinoForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/tabelas-precos'
            exact
            component={TabelaPrecoList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/tabelas-precos/:id'
            exact
            component={TabelaPrecoForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/clientes'
            exact
            component={ClientesList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/clientes/:id'
            exact
            component={ClienteForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/contatos'
            exact
            component={ContatosList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/contatos/:id'
            exact
            component={ContatoForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/filas'
            exact
            component={FilasList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/filas/:id'
            exact
            component={FilaForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/regras-fila'
            exact
            component={RegrasFilaList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/regras-fila/:id'
            exact
            component={RegraFilaForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/tipos-frete'
            exact
            component={TiposFreteList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/tipos-frete/:id'
            exportact
            component={TipoFreteForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/patios'
            exact
            component={PatiosList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/patios/:id'
            exact
            component={PatioForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/usuarios'
            exact
            component={UsuariosList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caixas'
            exact
            component={CaixasList}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caixas/:id'
            exact
            component={CaixaCard}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caixa-atual'
            exact
            component={CaixaRedirect}
            menuItems={menuItems}
          />
          <RouteACL
            path='/caixas/:id/operacoes-caixa/new'
            exact
            component={OperacoesCaixaForm}
            menuItems={menuItems}
          />
          <RouteACL
            path='/paineis-financeiro'
            exact
            component={Financeiro}
            menuItems={menuItems}
          />
          <RouteACL
            path='/paineis-patio'
            exact
            component={Patio}
            menuItems={menuItems}
          />
        </Switch>
        <OfflineAlert open={!navigator.onLine} />
      </>
    </AppWrap>
  )
}

export default withStyles(styles)(Home)

import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import classNames from 'classnames'
import { Select, Snackbar } from 'elentari'
import moment from 'moment'
import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import secureLocalStorage from 'react-secure-storage'
import { Roles, useUser } from '../../common'
import { caixaApi, operacaoCaixaApi, usuarioApi } from '../../services/Api'
import {
  imprimirFechamentoCaixa,
  imprimirTicketSangria,
  reimprimirFechamentoCaixa
} from '../../utils/impressora'
import {
  calcularOperacoes,
  centavosToRealNumber,
  centavosToRealStr,
  realToCentavos
} from '../../utils/monetary'
import OperacaoFormDialog from '../OperacoesCaixa/OperacaoFormDialog'
import OperacoesCaixaList from '../OperacoesCaixa/OperacoesCaixaList'
import { CaixaForm } from './CaixaForm'
import CaixaInfoDialog from './CaixaInfoDialog'

const styles = theme => ({
  root: {
    margin: '0 100px'
  },
  btn: {
    marginRight: theme.spacing(2)
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  rightBtns: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leftBtns: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  formContent: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%'
  },
  buttonContent: {
    width: '100%'
  },
  mleft: {
    marginLeft: theme.spacing(2)
  }
})

function CaixaCard({ classes, ...props }) {
  const { realm } = useUser()
  const operacoesRef = useRef()
  const [item, setItem] = useState()
  const [edit, setEdit] = useState(false)
  const [mensagem, setMensagem] = useState()
  const [dialogSangriaOpen, setDialogSangriaOpen] = useState(false)
  const [dialogSuprimentoOpen, setDialogSuprimentoOpen] = useState(false)
  const [dialogEstornoOpen, setDialogEstornoOpen] = useState(false)
  const [dialogFechamentoOpen, setDialogFechamentoOpen] = useState(false)
  const [formState, setFormState] = useState({
    status: 'edit',
    message: ''
  })

  const getCurrentId = params => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  async function fetchData(id) {
    if (id === 'new') {
      setEdit(true)
    } else {
      const response = await caixaApi.getOne(id, { include: 'operacoesCaixa' })
      if (response.ok) {
        const caixa = response.data
        const { entradas, sangrias, suprimentos, canceladas, estornos } =
          calcularOperacoes(response.data.operacoesCaixa)
        const saldo =
          entradas + suprimentos + caixa.valor_abertura - sangrias - estornos
        const responseUsuario = await usuarioApi.getOne(
          caixa.id_usuario_abertura
        )
        if (responseUsuario) {
          caixa.usuario_abertura = responseUsuario.data.username
        }
        caixa.valor_abertura = caixa.valor_abertura
          ? centavosToRealNumber(caixa.valor_abertura)
          : null
        caixa.valor_fechamento = caixa.valor_fechamento
          ? centavosToRealNumber(caixa.valor_fechamento)
          : null
        caixa.saldo = centavosToRealStr(saldo)
        caixa.cancelados = centavosToRealStr(canceladas)
        setItem(caixa)
        return caixa
      }
    }
  }

  useEffect(() => {
    fetchData(getCurrentId(props.match.params))
    const params = qs.parse(props.location.search.slice(1))
    if (params.mensagem) {
      setMensagem(params.mensagem)
    }
  }, [props.location, props.match])

  const getButtonLabel = submitting => {
    if (submitting) {
      return (
        <div>
          <CircularProgress size={24} className={classes.buttonProgress} />
          Enviando
        </div>
      )
    }
    switch (formState.status) {
      case 'success':
        return [
          <CheckCircleIcon
            key='successIcon'
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />,
          <span key='successLabel'>Fechado</span>
        ]
      case 'edit':
        return 'Fechar Caixa'
      case 'error':
        return 'Oops, algo errado'
      default:
        throw Error('Unexpected formState')
    }
  }

  const handleSubmit = async data => {
    const result = await caixaApi.fechar(item.id, data.info)
    if (result.ok) {
      const newItem = await fetchData(getCurrentId(props.match.params))
      const responseImpressao = await imprimirFechamentoCaixa(newItem)
      if (!responseImpressao.ok) {
        setMensagem('Não foi possível se comunicar com a impressora!')
      }
      setFormState({
        status: 'success',
        message: 'Caixa Fechado!'
      })
    } else {
      setFormState({
        status: 'error',
        message: result.message
      })
    }
    setTimeout(
      () =>
        setFormState({
          status: 'edit',
          ...formState
        }),
      4000
    )
  }

  const submitInfo = async data => {
    handleSubmit(data)
  }

  const handleSanckbarClose = () => {
    setMensagem('')
  }

  const submitSangria = async data => {
    const response = await operacaoCaixaApi.sangria({
      id_caixa: item.id,
      valor: -realToCentavos(data.valor),
      descricao: data.descricao,
      credentials: {
        username: data.usuario,
        password: data.senha
      }
    })
    if (response.ok) {
      await fetchData(getCurrentId(props.match.params))
      const responseImpressao = await imprimirTicketSangria(response.data)
      if (!responseImpressao.ok) {
        setMensagem('Não foi possível se comunicar com a impressora!')
      }
      operacoesRef.current.updatePage()
    } else {
      setMensagem(
        `Não foi possível realizar a sangria. ${response.data.error.message}`
      )
    }
  }

  const handleSangriaClick = () => {
    setDialogSangriaOpen(true)
  }

  const submitSuprimento = async data => {
    const response = await operacaoCaixaApi.suprimento({
      id_caixa: item.id,
      valor: realToCentavos(data.valor),
      descricao: data.descricao,
      credentials: {
        username: data.usuario,
        password: data.senha
      }
    })
    if (response.ok) {
      await fetchData(getCurrentId(props.match.params))
      operacoesRef.current.updatePage()
    } else {
      setMensagem(
        `Não foi possível realizar o suprimento. ${response.data.error.message}`
      )
    }
  }

  const submitEstorno = async data => {
    const response = await operacaoCaixaApi.estorno({
      id_caixa: item.id,
      valor: -realToCentavos(data.valor),
      descricao: data.descricao,
      credentials: {
        username: data.usuario,
        password: data.senha
      }
    })
    if (response.ok) {
      await fetchData(getCurrentId(props.match.params))
      operacoesRef.current.updatePage()
    } else {
      setMensagem(
        `Não foi possível realizar  o estorno. ${response.data.error.message}`
      )
    }
  }

  const handleSuprimentoClick = () => {
    setDialogSuprimentoOpen(true)
  }

  const handleEstornoClick = () => {
    setDialogEstornoOpen(true)
  }

  const handleReimprimirClick = async () => {
    const responseImpressao = await reimprimirFechamentoCaixa(item)
    if (!responseImpressao.ok) {
      setMensagem('Não foi possível se comunicar com a impressora!')
    }
  }

  const onChangeGuarita = event => {
    secureLocalStorage.setItem('guarita', event.target.value)
  }

  const guarita = secureLocalStorage.getItem('guarita')

  useEffect(() => {
    if (!guarita) {
      secureLocalStorage.setItem('guarita', 1)
    }
  }, [guarita])

  const renderButtons = (handleSubmit, submitting) => {
    const rolesAllow = [Roles.ADMIN, Roles.GESTOR]
    const isManagement = rolesAllow.includes(realm)

    if (!item.data_fechamento) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1} justify='flex-end'>
              <Grid item xs={12} md={isManagement ? 2 : 4}>
                <Field
                  hideEmpty
                  component={Select}
                  onChange={onChangeGuarita}
                  defaultValue={guarita}
                  options={[
                    { label: 'Entrada 1', value: 1 },
                    { label: 'Entrada 2', value: 2 }
                  ]}
                  label='Guarita'
                  name='guarita'
                  disabled={!isManagement}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {isManagement && (
                <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={3}>
                      <Button
                        variant='outlined'
                        size='large'
                        fullWidth
                        style={{ height: '100%' }}
                        onClick={() => handleSangriaClick()}
                      >
                        Sangria
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Button
                        variant='outlined'
                        size='large'
                        fullWidth
                        onClick={() => handleSuprimentoClick()}
                        style={{ height: '100%' }}
                      >
                        Suprimento
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Button
                        variant='outlined'
                        size='large'
                        fullWidth
                        style={{ height: '100%' }}
                        onClick={() => handleEstornoClick()}
                      >
                        Estorno
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} md>
                <Grid container spacing={1} justify='flex-end'>
                  <Grid item xs={12} md={4}>
                    <Button
                      variant='outlined'
                      size='large'
                      color='primary'
                      fullWidth
                      style={{ height: '100%' }}
                      onClick={() => {
                        props.history.push('/caminhoes-irregulares/new')
                      }}
                    >
                      Entrada Irregular
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      variant='outlined'
                      size='large'
                      color='primary'
                      fullWidth
                      style={{ height: '100%' }}
                      onClick={() => {
                        props.history.push(
                          props.history.location.pathname +
                            '/operacoes-caixa/new'
                        )
                      }}
                    >
                      Nova Entrada
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      disabled={submitting}
                      variant='contained'
                      size='large'
                      type='submit'
                      fullWidth
                      style={{ height: '100%' }}
                      onClick={() => {
                        setDialogFechamentoOpen(true)
                      }}
                      color='primary'
                    >
                      {getButtonLabel(submitting)}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container item xs={12} justify='flex-end'>
          <Grid item xs={isManagement ? 2 : 4}>
            <Button
              variant='contained'
              size='large'
              fullWidth
              onClick={() => handleReimprimirClick()}
              color='primary'
            >
              Reimprimir
            </Button>
          </Grid>
        </Grid>
      )
    }
  }

  return !!item ? (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant='button' gutterBottom color='primary'>
          {item.data_fechamento ? 'Caixa Fechado' : 'Caixa atual'}
        </Typography>
        <Typography variant='h6' gutterBottom>
          {item.nome} R$ {item.saldo}
        </Typography>
        {item.data_fechamento && (
          <Typography variant='h6' gutterBottom>
            Data fechamento:{' '}
            {moment(item.data_fechamento).format('DD/MM/YYYY HH:mm') + 'hrs'}
          </Typography>
        )}
        {item.info && (
          <Typography variant='body2' gutterBottom>
            Informações: {item.info}
          </Typography>
        )}
        <Form onSubmit={handleSubmit} initialValues={item}>
          {({ handleSubmit, submitting }) => (
            <React.Fragment>
              {renderButtons(handleSubmit, submitting)}
              <OperacaoFormDialog
                open={dialogSangriaOpen}
                defaultDescricao='Sangria'
                primaryAction={submitSangria}
                handleClose={() => {
                  setDialogSangriaOpen(false)
                }}
              />
              <OperacaoFormDialog
                open={dialogSuprimentoOpen}
                defaultDescricao='Suprimento'
                primaryAction={submitSuprimento}
                handleClose={() => {
                  setDialogSuprimentoOpen(false)
                }}
              />
              <OperacaoFormDialog
                open={dialogEstornoOpen}
                defaultDescricao='Estorno'
                primaryAction={submitEstorno}
                handleClose={() => {
                  setDialogEstornoOpen(false)
                }}
              />
              <CaixaInfoDialog
                open={dialogFechamentoOpen}
                primaryAction={submitInfo}
                handleClose={() => {
                  setDialogFechamentoOpen(false)
                }}
              />
            </React.Fragment>
          )}
        </Form>
      </div>
      <OperacoesCaixaList ref={operacoesRef} {...props} />
      <Snackbar
        autoHideDuration={6000}
        onClose={handleSanckbarClose}
        message={mensagem}
      />
    </React.Fragment>
  ) : edit ? (
    <CaixaForm {...props} />
  ) : null
}

export default withStyles(styles)(CaixaCard)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Search from '@material-ui/icons/Search'
import { filaApi, caminhaoApi, patioApi, destinoApi } from '../../services/Api'
import PatioCard from './PatioCard'
import { DestinoSelect } from '../Destinos/DestinoSelect'
import * as R from 'ramda'
import { loopback, autocompleteHelpers } from 'elentari'
import { CircularProgress } from '@material-ui/core'
const { toOption } = autocompleteHelpers
const { loopbackFilters } = loopback

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 16
  }
})

export const loadPatios = async inputValue => {
  const response = await patioApi.getList({
    where: {
      descricao: loopbackFilters.ilike(inputValue)
    },
    order: 'descricao asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('descricao'))
  }
  return []
}

class PainelPatios extends React.Component {
  state = {
    patios: [],
    filtro: {
      senha: '',
      id_destino: 0,
      all: true
    },
    user: JSON.parse(localStorage.user)
  }

  async fetchFilas () {
    const { filtro } = this.state
    const responsePatios = await patioApi.getList()
    if (responsePatios.ok) {
      const response = await filaApi.painel({ filtro })
      if (response.ok) {
        const filasGroup = R.groupBy(f => f.id_patio)(response.data)
        const patios = responsePatios.data.map(p => ({
          ...p,
          filas: filasGroup[p.id] ? filasGroup[p.id] : []
        }))
        this.setState({ patios })
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.filtro !== prevState.filtro) {
      this.fetchFilas()
    }
  }

  componentDidMount () {
    async function fetchDestino (id_usuario) {
      const response = await destinoApi.getList({ where: { id_usuario } })
      if (response.ok && response.data.length > 0) {
        return response.data[0].id
      }
      return 0
    }
    const urlToChangeStream = caminhaoApi.getUrlStream()
    const changeStream = new EventSource(urlToChangeStream)
    changeStream.addEventListener('data', () => {
      this.fetchFilas()
    })
    this.setState({
      changeStream
    })
    fetchDestino(this.state.user.id).then(id_destino => {
      this.setState(
        {
          filtro: {
            ...this.state.filtro,
            id_destino
          }
        },
        () => this.fetchFilas()
      )
    })
    this.timer = setInterval(() => {
      const { filtro } = this.state
      Promise.all([
        filaApi.painel({ filtro }),
        patioApi.getList(),
        destinoApi.getList({ where: { id_usuario: this.state.user.id } })
      ])
    }, 1000 * 60 * 5)
  }

  componentWillUnmount () {
    if (this.state.changeStream) {
      this.state.changeStream.close()
    }
    clearInterval(this.timer)
  }

  handleChange = name => event => {
    this.setState({
      filtro: {
        ...this.state.filtro,
        [name]: event.target.value
      }
    })
  }

  handleChangeDestino = id_destino => {
    this.setState({
      filtro: {
        ...this.state.filtro,
        id_destino
      }
    })
  }

  handleChangeCheckbox = name => event => {
    this.setState({
      filtro: {
        ...this.state.filtro,
        [name]: event.target.checked
      }
    })
  }

  onCloseFila = async id => {
    const response = await filaApi.update(id, { ativa: false })
    if (response.ok) {
      return { ok: true }
    }
    return { ok: false }
  }

  onOpenFila = async id => {
    const response = await filaApi.update(id, { ativa: true })
    if (response.ok) {
      return { ok: true }
    }
    return { ok: false }
  }

  renderFiltro = () => {
    const { classes } = this.props
    const { user, filtro } = this.state
    return (
      <Paper className={classes.root}>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid item xs={12} sm={3}>
            <TextField
              id='senha'
              fullWidth
              placeholder='Pesquisar Senha'
              variant='outlined'
              onChange={this.handleChange('senha')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DestinoSelect
              {...this.props}
              disabled={user.realm === 'destino'}
              value={filtro.id_destino}
              setDestinoId={this.handleChangeDestino}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filtro.all}
                  onChange={this.handleChangeCheckbox('all')}
                  value='all'
                  color='primary'
                />
              }
              label='Mostrar filas inativas'
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }

  render () {
    const { patios } = this.state
    return !!patios ? (
      <React.Fragment>
        {this.renderFiltro()}
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='center'
        >
          {patios.length ? (
            patios.map(patio => (
              <PatioCard
                {...this.props}
                key={patio.id}
                patio={patio}
                onCloseFila={this.onCloseFila}
                onOpenFila={this.onOpenFila}
              />
            ))
          ) : (
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction='row'
          justify='flex-end'
          alignItems='flex-end'
        />
      </React.Fragment>
    ) : (
      <Grid container direction='row' justify='center' alignItems='center'>
        <CircularProgress />
      </Grid>
    )
  }
}

export default withStyles(styles)(PainelPatios)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { destinoApi } from '../../services/Api'
import { FormApi, CrudForm, Checkbox } from 'elentari'

export function DestinoForm (props) {
  const validate = values => ({
    descricao: (values.descricao && values.descricao.trim()) ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data
      }
    })
    return result
  }

  const getItemDecorator = async ({ getItem }) => {
    const item = await getItem()
    return {
      ...item
    }
  }

  return (
    <FormApi
      initialData={{ status: true }}
      api={destinoApi}
      submitDecorator={submitDecorator}
      getItemDecorator={getItemDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Field
                fullWidth
                component={TextField}
                label='Descrição'
                name='descricao'
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Necessário Agendamento'
                name='agendamento'
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Ativo'
                name='status'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import React from 'react'
import { Route } from 'react-router-dom'
import * as R from 'ramda'

export function RouteACL({ component: Component, menuItems = [], ...rest }) {
  const { realm } = JSON.parse(localStorage.user)
  const items = R.flatten(menuItems.map((menuItem) => {
    if (menuItem.group) {
      return [menuItem, ...menuItem.items]
    }
    return menuItem
  }))
  return (
    <Route {...rest} render={props => {
      try {
        const pathname = props.location.pathname.split('/')[1]
        const roles = items.find(i => i.pathname.includes(pathname)).roles
        if (roles.length && roles.includes(realm)) {
          return <Component {...props} />
        }
        return props.history.push('/unauthorized')
      } catch (error) {
        return props.history.push('/unauthorized')
      }
    }} />
  )
}

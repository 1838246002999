import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { transportadoraApi } from '../../services/Api'
import { FormApi, CrudForm, Checkbox } from 'elentari'
import { parseCel, parseFone } from '../../utils/parse'

export function TransportadoraForm (props) {
  const validate = values => ({
    nome: values.nome && values.nome.trim() ? undefined : 'Obrigatório',
    telefone:
      values.telefone && values.telefone.trim() ? undefined : 'Obrigatório',
    celular:
      values.celular && values.celular.trim() ? undefined : 'Obrigatório',
    endereco:
      values.endereco && values.endereco.trim() ? undefined : 'Obrigatório',
    contato: values.contato && values.contato.trim() ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data
      }
    })
    return result
  }

  return (
    <FormApi
      initialData={{ status: true }}
      api={transportadoraApi}
      submitDecorator={submitDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Field fullWidth component={TextField} label='Nome' name='nome' />
            </Grid>
            <Grid item sm={3}>
              <Field
                fullWidth
                component={TextField}
                label='Telefone'
                name='telefone'
                parse={parseFone}
              />
            </Grid>
            <Grid item sm={3}>
              <Field
                fullWidth
                component={TextField}
                label='Celular'
                name='celular'
                parse={parseCel}
              />
            </Grid>
            <Grid item sm={6}>
              <Field
                fullWidth
                component={TextField}
                label='Endereço'
                name='endereco'
              />
            </Grid>
            <Grid item sm={4}>
              <Field
                fullWidth
                component={TextField}
                label='Contato'
                name='contato'
              />
            </Grid>
            <Grid item sm={2}>
              <Field
                component={Checkbox}
                type='checkbox'
                label='Ativo'
                name='status'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { patioApi } from '../../services/Api'
import { loopback, ListApi, List, Filter } from 'elentari'
const { loopbackFilters } = loopback

function PatiosFilter (props) {
  return (
    <Filter labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid spacing={3} container>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            label='Descrição'
            name='descricao'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function PatiosList (props) {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      descricao: {
        label: 'Descrição'
      }
    }
  }

  const handleDeleteDecorator = async (handleDeleteF, item) => {
    const result = await handleDeleteF(item)
    if (result.ok) {
      return result
    }
    return {
      ok: false,
      message: 'Não é possível excluir o pátio, pois há filas associadas.'
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    return items
  }

  return (
    <ListApi
      filtersConfig={{
        descricao: loopbackFilters.ilike
      }}
      api={patioApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount, handleDeleteF }) => (
        <div>
          <PatiosFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            deleteFItem={i => handleDeleteDecorator(handleDeleteF, i)}
            onClickEdit={item => props.history.push(`/patios/${item.id}`)}
            onClickNew={() => props.history.push('/patios/new')}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApi>
  )
}

import React from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import {
  loopback,
  ListApi,
  List,
  Filter,
  Autocomplete,
  autocompleteHelpers
} from 'elentari'
import {
  regraFilaApi,
  produtoApi,
  destinoApi,
  origemApi
} from '../../services/Api'
import { loadOrigens } from '../Origens'
import { loadDestinos } from '../Destinos'
import { loadProdutos } from '../Produtos'
const { loopbackFilters } = loopback
const { toOption } = autocompleteHelpers

export const loadRegrasFila = async inputValue => {
  const response = await regraFilaApi.getList({
    where: {
      sigla: loopbackFilters.ilike(inputValue)
    },
    order: 'sigla asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('sigla'))
  }
  return []
}

function RegrasFilaFilter (props) {
  return (
    <Filter
      formatFilters={{
        id_produto: value => value?.id,
        id_destino: value => value?.id,
        id_origem: value => value?.id
      }}
      labels={{ find: 'Buscar', clear: 'Limpar' }}
    >
      <Grid spacing={3} container>
        <Grid item sm={3}>
          <Field fullWidth component={TextField} label='Sigla' name='sigla' />
        </Grid>
        <Grid item sm={3}>
          <Field
            label='Produto'
            loadOptions={loadProdutos}
            component={Autocomplete}
            name='id_produto'
          />
        </Grid>
        <Grid item sm={3}>
          <Field
            label='Destino'
            loadOptions={loadDestinos}
            component={Autocomplete}
            name='id_destino'
          />
        </Grid>
        <Grid item sm={3}>
          <Field
            label='Origem'
            loadOptions={loadOrigens}
            component={Autocomplete}
            name='id_origem'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function RegrasFilaList (props) {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      sigla: {
        label: 'Sigla'
      },
      numero_senha: {
        label: 'Senha'
      },
      produto: {
        label: 'Produto',
        noOrder: true
      },
      destino: {
        label: 'Destino',
        noOrder: true
      },
      origem: {
        label: 'Origem',
        noOrder: true
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    if (items) {
      const promises = items.map(async item => {
        const [
          responseDestino,
          responseProduto,
          responseOrigem
        ] = await Promise.all([
          destinoApi.getOne(item.id_destino),
          produtoApi.getOne(item.id_produto),
          origemApi.getOne(item.id_origem)
        ])

        if (responseDestino.ok && responseProduto.ok && responseOrigem.ok) {
          item.destino = responseDestino.data.descricao
          item.id_destino = undefined
          item.produto = responseProduto.data.descricao
          item.id_produto = undefined
          item.origem = responseOrigem.data.descricao
          item.id_origem = undefined
        }

        return item
      })
      return Promise.all(promises)
    }
  }

  const handleDeleteDecorator = async (handleDeleteF, item) => {
    const result = await handleDeleteF(item)
    if (result.ok) {
      return result
    }
    return {
      ok: false,
      message: 'Não é possível excluir a regra, pois há filas associadas.'
    }
  }

  return (
    <ListApi
      filtersConfig={{
        sigla: loopbackFilters.ilike
      }}
      getPageDecorator={getPageDecorator}
      api={regraFilaApi}
    >
      {({ getPage, getCount, handleDeleteF }) => (
        <div>
          <RegrasFilaFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            deleteFItem={i => handleDeleteDecorator(handleDeleteF, i)}
            onClickEdit={item => props.history.push(`/regras-fila/${item.id}`)}
            onClickNew={() => props.history.push('/regras-fila/new')}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApi>
  )
}

import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'

const Loading = () => {
  return (
    <Grid
      container
      alignItems='center'
      alignContent='center'
      direction='row'
      style={{
        width: '100vw',
        height: '100vh'
      }}
    >
      <Grid
        container
        item
        alignItems='center'
        alignContent='center'
        direction='column'
      >
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default Loading

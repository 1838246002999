import * as R from 'ramda'

export const centavosToRealStr = number =>
  (number / 100).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

export const centavosToRealNumber = number => (number ? number / 100 : 0)

export const realToCentavos = number => number * 100

export const calcularOperacoes = (operacoesCaixa) => {
  const groupByTipo = R.groupBy(op => op.tipo)
  const operacoes = groupByTipo(operacoesCaixa)
  const entradas = operacoes['Entrada'] ? operacoes['Entrada'].map(R.prop('valor')).reduce(R.add, 0) : 0
  const sangrias = operacoes['Sangria'] ? operacoes['Sangria'].map(R.prop('valor')).reduce(R.add, 0) : 0
  const suprimentos = operacoes['Suprimento'] ? operacoes['Suprimento'].map(R.prop('valor')).reduce(R.add, 0) : 0
  const canceladas = operacoes['Cancelada'] ? operacoes['Cancelada'].map(R.prop('valor')).reduce(R.add, 0) : 0
  const estornos = operacoes['Estorno'] ? operacoes['Estorno'].map(R.prop('valor')).reduce(R.add, 0) : 0
  return {
    entradas,
    sangrias: Math.abs(sangrias),
    suprimentos,
    canceladas,
    estornos: Math.abs(estornos)
  }
}

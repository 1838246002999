import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  card: {
    width: 192,
    marginRight: 16
  }
})

function CaminhaoChamadaCard ({
  classes,
  caminhao,
  onCancel,
  onConfirm,
  ...props
}) {
  const handleCancelar = () => {
    onCancel(caminhao.id)
  }

  const handleConfirmar = () => {
    onConfirm(caminhao.id)
  }

  return (
    <Card className={classes.card}>
      <CardHeader title={`${caminhao.placa}`} />
      <CardContent>
        <div>
          <Typography variant='subtitle2' gutterBottom>
            {caminhao.senha}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {caminhao.regraFila.destino.descricao}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {caminhao.regraFila.produto.descricao}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {moment(caminhao.data_entrada).format('DD/MM/YY - HH:mm:ss')}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button size='small' color='secondary' onClick={handleCancelar}>
          Cancelar
        </Button>
        <Button size='small' color='primary' onClick={handleConfirmar}>
          Confirmar
        </Button>
      </CardActions>
    </Card>
  )
}

export default withStyles(styles)(CaminhaoChamadaCard)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { contatoApi } from '../../services/Api'
import { loopback, ListApi, List, Filter, Checkbox } from 'elentari'
const { loopbackFilters } = loopback

function ContatosFilter (props) {
  return (
    <Filter labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid spacing={3} container>
        <Grid item xs={12} sm={6}>
          <Field fullWidth component={TextField} label='E-mail' name='email' />
        </Grid>
        <Grid item xs={4} sm={3}>
          <Field
            fullWidth
            component={Checkbox}
            type='checkbox'
            label='Caixa'
            name='caixa'
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <Field
            fullWidth
            component={Checkbox}
            type='checkbox'
            label='Financeiro'
            name='financeiro'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function ContatosList (props) {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      email: {
        label: 'E-mail'
      },
      caixa: {
        label: 'Caixa',
        format: boolean => (boolean ? 'Sim' : 'Não')
      },
      financeiro: {
        label: 'Financeiro',
        format: boolean => (boolean ? 'Sim' : 'Não')
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    return items
  }

  return (
    <ListApi
      filtersConfig={{
        email: loopbackFilters.ilike
      }}
      api={contatoApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount, handleDeleteF }) => (
        <div>
          <ContatosFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            deleteFItem={handleDeleteF}
            onClickEdit={item => props.history.push(`/contatos/${item.id}`)}
            onClickNew={() => props.history.push('/contatos/new')}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApi>
  )
}

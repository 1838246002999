import React from 'react'
import { Grid, Typography } from '@material-ui/core'

export function ErrorUnauthorized() {
  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='h4' gutterBottom align='center'>
          401 Authorization Required
        </Typography>
      </Grid>
    </Grid>
  )
}

import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import {
  Autocomplete,
  MaskedField,
  Select,
  SimpleDialog,
  Snackbar,
  autocompleteHelpers
} from 'elentari'
import { TextField } from 'final-form-material-ui'
import qs from 'qs'
import React, { Fragment, useEffect, useState } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import secureLocalStorage from 'react-secure-storage'
import { Spacer } from '../../components'
import {
  caminhaoApi,
  clienteApi,
  filaApi,
  regraFilaApi
} from '../../services/Api'
import { formatEixo, formatPlaca } from '../../utils/format'
import { imprimirTickets } from '../../utils/impressora'
import { messages } from '../../utils/messages.js'
import { centavosToRealStr } from '../../utils/monetary'
import { loadClientes } from '../Clientes'
import { loadDestinos } from '../Destinos'
import { loadOrigens } from '../Origens'
import { loadProdutos } from '../Produtos'
import { RegraFilaDialog } from '../RegrasFila'
import { loadTiposFrete } from '../TiposFrete'
import { nfeRegex, opcoesPagamento } from './utils'
import { getValorByPrecoEixo } from '../Caminhoes/utils/getValorByPrecoEixo'
import { ModalAdminCredentials } from './components'
import { useModalControl } from '../../common'

const { toOption } = autocompleteHelpers

const styles = theme => ({
  root: {
    position: 'relative',
    width: 'calc(100% + 48px)',
    overflowY: 'hidden',
    backgroundColor: '#F6F9FB',
    display: 'flex',
    marginLeft: -24,
    marginTop: -24,
    marginBottom: -24,
    height: '100%'
  },
  leftContainer: {
    margin: theme.spacing(12),
    marginBottom: 0,
    paddingBottom: theme.spacing(5),
    marginLeft: theme.spacing(25),
    height: '100%'
  },
  rightContainer: {
    margin: theme.spacing(12),
    marginBottom: 0,
    paddingBottom: theme.spacing(5),
    marginRight: theme.spacing(25),
    height: '100%'
  },
  panel: {
    height: '100vh',
    width: '100%',
    position: 'relative',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      height: '0',
      width: '0'
    }
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white
  },
  numberBg: {
    fontSize: 356,
    fontWeight: 700
  },
  colorWhite: {
    color: theme.palette.common.white
  },
  colorGrey: {
    color: '#F6F9FB'
  },
  leftNumber: {
    float: 'left',
    position: 'absolute'
  },
  rightNumber: {
    float: 'right',
    top: 0,
    right: 0,
    position: 'absolute'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: 'rgba(246, 249, 251, 0.15)'
  }
})

const OperacoesCaixaForm = ({ classes, ...props }) => {
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [filaSelecionada, setFilaSelecionada] = useState(false)
  const [filas, setFilas] = useState([])
  const [senha, setSenha] = useState('-')
  const [valor, setValor] = useState(0)
  const [regra, setRegra] = useState()
  const [produto, setProduto] = useState()
  const [origem, setOrigem] = useState()
  const [placa, setPlaca] = useState()
  const [numeroEixo, setNumeroEixo] = useState()
  const [nfe, setNfe] = useState()
  const [nfeChave, setNfeChave] = useState()
  const [cliente, setCliente] = useState(null)
  const [frete, setFrete] = useState()
  const [opcaoPagamentoSelecionada, setOpcaoPagamentoSelecionada] = useState()
  const [mensagem, setMensagem] = useState('')
  const [alertaFilasInativa, setAlertaFilasInativa] = useState(false)
  const [regraDialogProps, setRegraDialogProps] = useState({
    open: false,
    handleClose: () => {
      setRegraDialogProps({ ...regraDialogProps, open: false })
    }
  })
  const theme = useTheme()
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const {
    handleClose: handleCloseModalAdmin,
    handleOpen: handleOpenModalAdmin,
    isOpen: isOpenModalAdmin
  } = useModalControl()

  const params = qs.parse(props.location.search.slice(1))

  const fetchData = async (id_cliente, id_regra_fila, numero_eixo) => {
    const resultSenha = await regraFilaApi.getOne(id_regra_fila)
    if (resultSenha.ok) {
      setSenha(`${resultSenha.data.sigla}-${resultSenha.data.numero_senha}`)
    }
    const resultValor = await clienteApi.getOne(id_cliente, {
      include: 'tabelaPreco'
    })

    if (resultValor.ok) {
      const data = resultValor.data

      setCliente(data)

      const valorReal = getValorByPrecoEixo(numero_eixo, data.tabelaPreco)

      setValor(valorReal)
    }
  }

  const handleSubmitStep1 = async ({
    placa,
    numeroEixo,
    nfe,
    nfeChave,
    cliente,
    destino,
    origem,
    produto,
    frete
  }) => {
    const regraResponse = await regraFilaApi.getList({
      where: {
        and: [
          { id_origem: origem.value },
          { id_produto: produto.value },
          { id_destino: destino.value }
        ]
      }
    })
    if (regraResponse.ok) {
      if (regraResponse.data.length) {
        const regra = regraResponse.data[0]
        const filasResponse = await filaApi.filasDisponiveis({
          id_regra_fila: regra.id
        })

        if (filasResponse.ok) {
          let {
            filaIndicada,
            filasComEspaco,
            outrasOpcoes
          } = filasResponse.data
          filaIndicada = filaIndicada.map(fila => ({
            ...fila,
            numero: `${fila.patio} - Fila: ${
              fila.numero
            } - Quantidade caminhões: ${fila.caminhoes}`
          }))
          if (filasComEspaco.length > 0) {
            filasComEspaco = filasComEspaco.map(fila => ({
              ...fila,
              numero: `${fila.patio} - Fila: ${
                fila.numero
              }- REABRIR - Quantidade caminhões: ${fila.caminhoes}`
            }))
          }
          if (outrasOpcoes.length > 0) {
            outrasOpcoes = outrasOpcoes.map(fila => ({
              ...fila,
              numero: `${fila.patio} - Fila: ${
                fila.numero
              } * - Quantidade caminhões: ${fila.caminhoes}`
            }))
            setAlertaFilasInativa(true)
          }

          setFilas([...filaIndicada, ...filasComEspaco, ...outrasOpcoes])
          setRegra(regra)
          setProduto(produto)
          setOrigem(origem)
          setPlaca(placa)
          setNumeroEixo(numeroEixo)
          setNfe(nfe)
          setNfeChave(nfeChave)
          setFrete(frete)
          setStep(2)
          fetchData(cliente.value, regra.id, numeroEixo)
        }
      } else {
        setRegraDialogProps({
          ...regraDialogProps,
          open: true,
          origem,
          produto,
          destino
        })
      }
    }
  }

  const submitRegistrarEntrada = ({
    filaId = '',
    opcaoPagamento: opcaoPagamentoValue = '',
    credentials = {}
  }) => {
    const idCaixa = props.match.params.id
    const guarita = secureLocalStorage.getItem('guarita')

    const idFila = filaId || filaSelecionada
    const opcaoPagamento = opcaoPagamentoValue || opcaoPagamentoSelecionada

    const data = {
      id_caixa: idCaixa,
      id_regra: regra.id,
      id_fila: idFila,
      id_cliente: cliente.id,
      id_tipo_frete: frete.value,
      placa,
      numero_eixo: numeroEixo,
      nfe,
      nfe_chave: nfeChave,
      caminhaoEspecialId: params.caminhaoId,
      guarita,
      opcao_pagamento: opcaoPagamento
    }

    if (isOpenModalAdmin) {
      return caminhaoApi.registrarEntradaAutorizadaAdmin({
        ...data,
        credentials
      })
    }

    return caminhaoApi.registrarEntrada(data)
  }

  const submitStep2 = async ({
    filaId = '',
    opcaoPagamento = '',
    credentials = {}
  }) => {
    setFetching(true)

    const result = await submitRegistrarEntrada({
      filaId,
      opcaoPagamento,
      credentials
    })

    if (result.ok) {
      const idFila = filaId || filaSelecionada

      const responseFila = await filaApi.getOne(idFila)
      const numero = responseFila.ok ? responseFila.data.numero : 0
      const data = {
        data_entrada: result.data.data_entrada,
        placa: result.data.placa,
        numero_eixo: result.data.numero_eixo,
        produto: produto.label,
        frete: frete.label,
        fila: numero,
        nota: result.data.nfe,
        valor,
        senha: result.data.senha,
        caminhaoEspecialId: params.caminhaoId
      }

      const idCaixa = props.match.params.id

      const responseImpressao = await imprimirTickets(data)
      const paramsImpressao = responseImpressao.ok
        ? ''
        : '?mensagem=Erro ao comunicar com a Impressora!'
      props.history.push(`/caixas/${idCaixa}${paramsImpressao}`)
    } else {
      const message = result.data?.error?.message

      if (message === 'O motorista não é cadastrado na frota informada.') {
        handleOpenModalAdmin()
      } else {
        setMensagem(message ?? 'Ocorreu um erro.')
      }
    }

    setFetching(false)
  }

  const handleSubmitStep2 = async ({ fila, opcaoPagamento }) => {
    const filaSelecionada = filas.find(f => f.id === fila)

    if (filaSelecionada.caminhoes >= filaSelecionada.max_caminhoes) {
      setFilaSelecionada(fila)
      setOpcaoPagamentoSelecionada(opcaoPagamento)
      setOpen(true)
    } else {
      submitStep2({ filaId: fila, opcaoPagamento })
    }
  }

  const camposObrigatorio = value => (value ? undefined : 'Obrigatório')

  const validNfeChave = value => {
    const nfeChaveFormatted = value?.replaceAll('_', '')

    if (!nfeChaveFormatted?.length) {
      return 'Obrigatório'
    }

    if (nfeChaveFormatted?.length !== 44) {
      return messages.MESSAGE_FIELD_EQUAL_LENGHT('NFE Chave', 44)
    }
  }

  const validNumeroEixo = value =>
    value > 9 || value < 1
      ? 'O número de eixos deve ser entre 1 e 9'
      : undefined
  const validateForm2 = values => ({
    fila: values.fila ? undefined : 'Obrigatório',
    opcaoPagamento: values.opcaoPagamento ? undefined : 'Obrigatório'
  })
  const validateForm1 = values => ({
    placa: camposObrigatorio(values.placa),
    numeroEixo:
      camposObrigatorio(values.numeroEixo) ||
      validNumeroEixo(values.numeroEixo),
    nfeChave: validNfeChave(values.nfeChave),
    cliente: camposObrigatorio(values.cliente),
    destino: camposObrigatorio(values.destino),
    origem: camposObrigatorio(values.origem),
    produto: camposObrigatorio(values.produto),
    frete: camposObrigatorio(values.frete)
  })

  let leftPanelStyles = [classes.panel]
  let rightPanelStyles = [classes.panel]
  let leftNumber = [classes.numberBg, classes.leftNumber]
  let rightNumber = [classes.numberBg, classes.rightNumber]

  if (step === 1) {
    leftPanelStyles = classNames(leftPanelStyles, classes.whiteBg)
    leftNumber = classNames(leftNumber, classes.colorGrey)
    rightNumber = classNames(rightNumber, classes.colorWhite)
  } else {
    rightPanelStyles = classNames(rightPanelStyles, classes.whiteBg)
    leftNumber = classNames(leftNumber, classes.colorWhite)
    rightNumber = classNames(rightNumber, classes.colorGrey)
  }

  const caminhaoEspecial = params => {
    if (params.caminhaoId) {
      const caminhaoIrregular = {
        placa: params.placa,
        destino: {
          id: params.destinoId,
          value: params.destinoId,
          label: params.destinoNome
        },
        numeroEixo: params.numeroEixo,
        nfeChave,
        origem,
        produto,
        frete,
        nfe,
        cliente
      }
      return caminhaoIrregular
    }
    return null
  }

  const handleSnackbarClose = () => {
    setMensagem('')
  }

  const handleOpcoesPagamentoOptions = () => {
    const value = handleOpcaoPagamentoValue()

    if (!value) return opcoesPagamento

    const newArr = opcoesPagamento.filter(option => {
      return option.value === value
    })

    return newArr
  }

  const handleOpcaoPagamentoValue = () => {
    if (step === 1 || !cliente) return

    const hasFaturamento = cliente.faturamento
    const hasPagamentoVista = cliente.pagamento_vista

    if (hasFaturamento === hasPagamentoVista) return

    const value = hasFaturamento
      ? 'faturamento'
      : hasPagamentoVista
      ? 'pagamento_vista'
      : undefined

    return value
  }

  return (
    <div className={classes.root}>
      <RegraFilaDialog {...regraDialogProps} {...props} {...params} />
      <div className={leftPanelStyles}>
        <Typography className={leftNumber}>1</Typography>
        <div className={classes.leftContainer}>
          {step === 2 ? <div className={classes.overlay} /> : null}
          <Typography
            variant='h4'
            gutterBottom
            color={step === 2 ? 'textSecondary' : 'textPrimary'}
          >
            Nova entrada
          </Typography>
          <Typography
            variant='body1'
            color={step === 2 ? 'textSecondary' : 'textPrimary'}
          >
            Entre com os dados do caminhão para gerar um nova entrada
          </Typography>
          <Form
            onSubmit={handleSubmitStep1}
            validate={validateForm1}
            initialValues={caminhaoEspecial(params)}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      parse={formatPlaca}
                      label='Placa'
                      name='placa'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label='Número de eixos'
                      name='numeroEixo'
                      type='number'
                      parse={formatEixo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={MaskedField}
                      label='NFE Chave'
                      name='nfeChave'
                      mask={nfeRegex}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label='NFE Número - Série'
                      name='nfe'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={Autocomplete}
                      loadOptions={loadDestinos}
                      label='Destino'
                      name='destino'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={Autocomplete}
                      loadOptions={loadOrigens}
                      label='Origem'
                      name='origem'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Autocomplete}
                      loadOptions={loadProdutos}
                      label='Produto'
                      name='produto'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={Autocomplete}
                      loadOptions={loadClientes}
                      label='Cliente'
                      name='cliente'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Autocomplete}
                      loadOptions={loadTiposFrete}
                      label='Tipos de Frete'
                      name='frete'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant='contained'
                      color={submitting ? 'default' : 'primary'}
                      type='submit'
                      disabled={submitting || step === 2}
                    >
                      {submitting ? (
                        <React.Fragment>
                          <CircularProgress size={24} />
                          Enviando
                        </React.Fragment>
                      ) : (
                        <React.Fragment>Continuar</React.Fragment>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </div>
      </div>
      <div className={rightPanelStyles}>
        <Typography className={rightNumber}>2</Typography>
        <div className={classes.rightContainer}>
          {step === 1 ? <div className={classes.overlay} /> : null}
          <Typography
            variant='h4'
            gutterBottom
            color={step === 1 ? 'textSecondary' : 'textPrimary'}
          >
            Cobrança
          </Typography>
          <Form onSubmit={handleSubmitStep2} validate={validateForm2}>
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} justify='center'>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      hideEmpty
                      component={Select}
                      options={filas.map(toOption('numero'))}
                      defaultValue={filas.length && filas[0].id}
                      label='Fila'
                      name='fila'
                    />
                    {alertaFilasInativa && (
                      <Typography variant='caption'>
                        {' '}
                        * Ativar fila inativa
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Spacer y={1} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color={step === 1 ? 'textSecondary' : 'textPrimary'}
                    >
                      Senha
                    </Typography>
                    <Typography variant='h4' color='primary'>
                      {senha}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color={step === 1 ? 'textSecondary' : 'textPrimary'}
                    >
                      Valor
                    </Typography>
                    <Typography variant='h4' color='primary'>
                      {centavosToRealStr(valor)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      hideEmpty
                      component={Select}
                      options={handleOpcoesPagamentoOptions()}
                      label='Opção de Pagamento'
                      name='opcaoPagamento'
                      defaultValue={handleOpcaoPagamentoValue()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Spacer y={1} />
                  </Grid>
                  <FormSpy>
                    {() => (
                      <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        direction={isDownMd ? 'column-reverse' : undefined}
                      >
                        <Grid item md={12} lg={3} fullWidth>
                          <Button
                            variant='outlined'
                            disabled={submitting || step === 1}
                            onClick={() => setStep(1)}
                            fullWidth
                          >
                            Voltar
                          </Button>
                        </Grid>
                        <Grid item md={12} lg={9} fullWidth>
                          <Button
                            fullWidth
                            variant='contained'
                            color={submitting ? 'default' : 'primary'}
                            type='submit'
                            disabled={submitting || step === 1 || fetching}
                          >
                            {fetching ? (
                              <React.Fragment>
                                <CircularProgress size={24} />
                                Enviando
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                Confirmar recebimento
                              </React.Fragment>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </FormSpy>
                </Grid>

                <FormSpy subscription={{ values: true }}>
                  {() => (
                    <Fragment>
                      <ModalAdminCredentials
                        open={isOpenModalAdmin}
                        handleClose={handleCloseModalAdmin}
                        primaryAction={data => {
                          submitStep2({
                            filaId: values?.fila,
                            opcaoPagamento: values?.opcaoPagamento,
                            credentials: data
                          })
                        }}
                      />
                      <SimpleDialog
                        open={!!open}
                        buttonLabel='Voltar'
                        handleClose={() => setOpen(false)}
                        primaryAction={() => {
                          submitStep2({
                            filaId: values?.fila,
                            opcaoPagamento: values?.opcaoPagamento
                          })
                        }}
                        title='A fila selecionada consta como cheia. Deseja incluir um caminhão nela?'
                        primaryActionButtonLabel='Sim'
                      />
                    </Fragment>
                  )}
                </FormSpy>
              </form>
            )}
          </Form>

          <Snackbar
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={mensagem}
          />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(OperacoesCaixaForm)

import React from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import {
  FormApi,
  Checkbox,
  CrudForm,
  Autocomplete,
  autocompleteHelpers,
  MountEntity
} from 'elentari'
import {
  regraFilaApi,
  produtoApi,
  destinoApi,
  origemApi
} from '../../services/Api'
import { loadOrigens } from '../Origens'
import { loadDestinos } from '../Destinos'
import { loadProdutos } from '../Produtos'
const { toOption } = autocompleteHelpers

export function RegraFilaForm (props) {
  const validate = values => ({
    sigla: values.sigla && values.sigla.trim() ? undefined : 'Obrigatório',
    numero_senha: values.numero_senha ? undefined : 'Obrigatório',
    produto: values.produto ? undefined : 'Obrigatório',
    destino: values.destino ? undefined : 'Obrigatório',
    origem: values.origem ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        id_produto: data.produto.value,
        id_destino: data.destino.value,
        id_origem: data.origem.value
      }
    })
    return result
  }

  const getItemDecorator = async ({ getItem, filter }) => {
    if (props.match.params.id === 'new') {
      return getItem(filter)
    }
    const result = await MountEntity(props, regraFilaApi.getOne)
      .populate(({ data, id }) => ({
        produto: produtoApi.getOne(data.id_produto),
        destino: destinoApi.getOne(data.id_destino),
        origem: origemApi.getOne(data.id_origem)
      }))
      .clean(['id_produto', 'id_destino', 'id_origem'])
      .map(data => ({
        ...data,
        produto: toOption('descricao')(data.produto),
        destino: toOption('descricao')(data.destino),
        origem: toOption('descricao')(data.origem)
      }))
      .getItem()
    return result
  }

  return (
    <FormApi
      api={regraFilaApi}
      submitDecorator={submitDecorator}
      getItemDecorator={getItemDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                component={TextField}
                label='Sigla'
                name='sigla'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                component={TextField}
                label='Senha'
                name='numero_senha'
                type='number'
                parse={number => (number < 0 ? 0 : number)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Reiniciar contagem todo mês'
                name='reset_senha'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                label='Produto'
                loadOptions={loadProdutos}
                component={Autocomplete}
                name='produto'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                label='Destino'
                loadOptions={loadDestinos}
                component={Autocomplete}
                name='destino'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                label='Origem'
                loadOptions={loadOrigens}
                component={Autocomplete}
                name='origem'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

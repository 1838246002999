import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { caminhaoApi } from '../../../services/Api'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'

const styles = theme => ({
  card: {
    padding: theme.spacing(2),
    height: 555
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  number: {
    marginTop: 75
  }
})

const QuantidadeCaminhoesCard = props => {
  const { classes } = props
  const [data, setData] = useState([])

  const fetchData = () => {
    caminhaoApi.countByOrigemProduto().then(res => {
      if (res.ok) {
        setData(res.data)
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Card className={classes.card}>
      <Typography variant='subtitle1' align='center' className={classes.title}>
        Quantidade de caminhões
      </Typography>
      <Paper>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Origem</TableCell>
              <TableCell align='left'>Produto</TableCell>
              <TableCell align='left'>Qtd Caminhões</TableCell>
              <TableCell align='left'>Mais tempo na fila</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow key={row.origem + row.produto}>
                <TableCell align='left'>{row.origem}</TableCell>
                <TableCell align='left'>{row.produto}</TableCell>
                <TableCell align='left'>{row.count}</TableCell>
                <TableCell align='left'>
                  {moment(row.caminhaoMaiorTempo).format('DD/MM/YYYY HH:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  )
}

export default withStyles(styles)(QuantidadeCaminhoesCard)

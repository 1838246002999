import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Box, Container, Grid, Typography } from '@material-ui/core'
import config from '../../Config'
import background from '../../images/background.svg'
import logo from '../../images/logoMenu.svg'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: '200px',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.getContrastText('#FBE0C5')
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    width: 200
  },
  page: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
}))

const Login = () => {
  const classes = useStyles()

  async function handleLogin () {
    const ssoUrlLogin = `${config.ssoWebUrl}/login`
    const patioWebUrl = config.webUrl
    const clientId = config.ssoClientId
    const search = window.location.search
    const params = new URLSearchParams(search)
    const redirectRoute = params.get('redirect_route')
    const redirectUrl = `${patioWebUrl}/callback&clientId=${clientId}${
      redirectRoute ? `&redirect_route=${redirectRoute}` : ''
    }`

    const url = `${ssoUrlLogin}?url=${redirectUrl}`

    window.location.assign(url)
  }

  return (
    <Box
      bgcolor='#263238'
      height='100vh'
      width='100vw'
      className={classes.page}
    >
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} className={classes.login}>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <img src={logo} className={classes.logo} alt='G10 Logo' />
            </Box>

            <Typography variant='h4' className={classes.title}>
              Pátio de Triagem
            </Typography>

            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={handleLogin}
            >
              Entrar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Login

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { filaApi, regraFilaApi, patioApi } from '../../services/Api'
import { loadRegrasFila } from '../RegrasFila'
import { loadPatios } from '../Patios'
import {
  FormApi,
  CrudForm,
  Autocomplete,
  autocompleteHelpers,
  Checkbox,
  MountEntity
} from 'elentari'
const { toOption } = autocompleteHelpers

export function FilaForm (props) {
  const validate = values => ({
    numero: values.numero ? undefined : 'Obrigatório',
    patio: values.patio ? undefined : 'Obrigatório',
    max_caminhoes: values.max_caminhoes ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        id_regra_fila: data.regra_fila ? data.regra_fila.value : null,
        id_patio: data.patio.value
      }
    })
    return result
  }

  const getItemDecorator = async ({ getItem, filter }) => {
    if (props.match.params.id === 'new') {
      return getItem(filter)
    }
    const result = await MountEntity(props, filaApi.getOne)
      .populate(({ data, id }) => {
        if (data.id_regra_fila) {
          return {
            regra_fila: regraFilaApi.getOne(data.id_regra_fila),
            patio: patioApi.getOne(data.id_patio)
          }
        }
        return {
          patio: patioApi.getOne(data.id_patio)
        }
      })
      .clean(['id_regra_fila', 'id_patio'])
      .map(data => ({
        ...data,
        regra_fila: data.regra_fila ? toOption('sigla')(data.regra_fila) : null,
        patio: toOption('descricao')(data.patio)
      }))
      .getItem()
    return result
  }

  return (
    <FormApi
      initialData={{ ativa: false }}
      api={filaApi}
      submitDecorator={submitDecorator}
      getItemDecorator={getItemDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Field
                fullWidth
                component={TextField}
                label='Número'
                name='numero'
                type='number'
                parse={number => (number < 0 ? 0 : number)}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                component={Autocomplete}
                loadOptions={loadRegrasFila}
                label='Regra'
                name='regra_fila'
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                component={Autocomplete}
                loadOptions={loadPatios}
                label='Pátio'
                name='patio'
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                fullWidth
                component={TextField}
                label='Qtd Max caminhões'
                name='max_caminhoes'
                type='number'
                parse={number => (number < 0 ? 0 : number)}
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Ativa'
                name='ativa'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {
  logReimpressaoApi
} from '../../services/Api'
import moment from 'moment'

const styles = theme => ({
  root: {
    padding: 24
  },
})

function LogReimpressaoCard({ classes, id_caminhao, ...props }) {
  const [item, setItem] = useState()

  async function fetchData(id) {
    const response = await logReimpressaoApi.getList({where: {id_caminhao: id}})
    if (response.ok) {
      setItem(response.data)
    }
  }

  useEffect(() => {
    if (!props.refetch) {
      fetchData(id_caminhao)
    }
  }, [id_caminhao, props.refetch])

  const formatData = data => {
    return moment(data).format('DD/MM/YYYY - HH:mm:ss')
  }

  const renderLog = (log) => {
    return (
      <React.Fragment key={log.id}>
        <Typography variant='body1' gutterBottom>
          <strong>Data: </strong> {formatData(log.data)} <strong>Usuário: </strong> {log.usuario.username}
        </Typography>
      </React.Fragment>
    )
  }

  return (item && item.length) ? (
    <Paper className={classes.root}>
      <Grid container direction='row' justify='flex-start' alignItems='center'>
        <Grid item>
          <Typography variant='body1' gutterBottom>
            <strong>Total de reimpressões: </strong> {item.length}
          </Typography>
          {item.map(log => renderLog(log))}
        </Grid>
      </Grid>
    </Paper>
  ) : null
}

export default withStyles(styles)(LogReimpressaoCard)

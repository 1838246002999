import { clienteApi, usuarioApi } from '../../../services/Api'
import moment from 'moment'
import { getValorByPrecoEixo } from './getValorByPrecoEixo'

const formatFields = {
  data_entrada: date =>
    date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '',
  data_saida: date => (date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : ''),
  data_cancelamento: date =>
    date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : ''
}

export const formatValues = async items => {
  // Para não quebrar o List em caso do sistema offline
  if (items.ok === false) return []

  const values = []
  const users = {}
  const clients = {}

  for (const item of items) {
    for (const key of Object.keys(item)) {
      const format = formatFields[key]
      if (format) item[key] = format(item[key])
    }

    if (item.data_cancelamento) {
      item.senha = `${item.senha} - Cancelada`
    }

    if (!users[item.id_usuario]) {
      const responseUser = await usuarioApi.getOne(item.id_usuario)
      users[`${item.id_usuario}`] = responseUser.data.username
    }

    if (!clients[item.id_cliente]) {
      const responseCliente = await clienteApi.getOne(item.id_cliente, {
        include: 'tabelaPreco'
      })
      clients[item.id_cliente] = responseCliente.data
    }

    item.usuario = users[item.id_usuario] || 'N/A'
    const currentClient = clients[item.id_cliente]
    if (currentClient) {
      const tabelaPreco = currentClient.tabelaPreco
      const numeroEixo = item.numero_eixo

      item.valor_real =
        numeroEixo && tabelaPreco
          ? getValorByPrecoEixo(numeroEixo, tabelaPreco)
          : null

      item.cliente = currentClient.nome
    } else {
      item.cliente = 'N/A'
      item.valor_real = 'N/A'
    }

    const value = {
      id: item.id,
      usuario: item.usuario,
      placa: item.placa,
      senha: item.senha,
      data_entrada: item.data_entrada,
      data_saida: item.data_saida,
      data_cancelamento: item.data_cancelamento,
      observacao: item.observacao,
      numero_eixo: item.numero_eixo,
      valor_real: item.valor_real,
      cliente: item.cliente,
      opcao_pagamento: item.opcao_pagamento
    }

    values.push(value)
  }
  return values
}

const isSecondLevel = pathname => /\/\w+\/\d+\/\w+/i.test(pathname)

export const customHandleDeleteF = api => async (item, location = {}) => {
  let response
  if (isSecondLevel(location.pathname)) {
    response = await api.deleteFRelation(item.id, { location })
  } else {
    response = await api.deleteF(item.id)
  }
  if (response.ok) {
    return {
      ok: true,
      message: 'Item removido!'
    }
  }
  return {
    ok: response.ok,
    message: 'Não foi possível remover o registro'
  }
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import moment from 'moment'
import { transportadoraApi, usuarioApi } from '../../services/Api'
import {
  loopback,
  ListApi,
  List,
  Select,
  Filter,
  autocompleteHelpers
} from 'elentari'

const { loopbackFilters } = loopback
const { toOption } = autocompleteHelpers

export const loadTransportadoras = async inputValue => {
  const response = await transportadoraApi.getList({
    where: {
      nome: loopbackFilters.ilike(inputValue)
    },
    order: 'nome asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('nome'))
  }
  return []
}

const TransportadorasFilter = () => {
  return (
    <Filter
      labels={{ find: 'Buscar', clear: 'Limpar' }}
      formatFilters={{
        id_modificado_por: values => values.map(value => value.id)
      }}
    >
      <Grid spacing={3} container>
        <Grid item sm={4}>
          <Field fullWidth component={TextField} label='Nome' name='nome' />
        </Grid>
        <Grid item sm={4}>
          <Field
            fullWidth
            component={TextField}
            label='Contato'
            name='contato'
          />
        </Grid>
        <Grid item sm={4}>
          <Field
            label='Status'
            hideEmpty
            component={Select}
            options={[
              { label: 'Inativo', value: false },
              { label: 'Ativo', value: true }
            ]}
            name='status'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function TransportadorasList (props) {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      nome: {
        label: 'Nome'
      },
      telefone: {
        label: 'Telefone'
      },
      celular: {
        label: 'Celular'
      },
      endereco: {
        label: 'Endereço'
      },
      contato: {
        label: 'Contato'
      },
      data_criacao: {
        label: 'Data de criação',
        format: date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')
      },
      data_modificacao: {
        label: 'Data de Modificação',
        format: date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')
      },
      modificado_por: {
        label: 'Modificado por',
        noOrder: true
      },
      status: {
        label: 'Status',
        format: boolean => (boolean ? 'Ativo' : 'Inativo')
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    if (items.ok === false) return []

    const promises = items.map(async item => {
      const response = await usuarioApi.getOne(item.id_modificado_por)
      if (response.ok) {
        item.modificado_por = response.data.username
        return item
      }
      return item
    })
    return Promise.all(promises)
  }

  const transformWhere = where => {
    const copy = { ...where }
    delete copy.data_criacao
    delete copy.data_modificacao
    if (where.data_criacao) {
      const date = moment(where.data_criacao).format('YYYY-MM-DD')
      const tomorow = moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      copy.data_criacao = { between: [date, tomorow] }
    }
    if (where.data_modificacao) {
      const date = moment(where.data_modificacao).format('YYYY-MM-DD')
      const tomorow = moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      copy.data_modificacao = { between: [date, tomorow] }
    }
    return copy
  }

  return (
    <ListApi
      filtersConfig={{
        nome: loopbackFilters.ilike,
        contato: loopbackFilters.ilike
      }}
      transformWhere={transformWhere}
      api={transportadoraApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount, handleDeleteF }) => (
        <div>
          <TransportadorasFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            deleteFItem={handleDeleteF}
            labelNew='Novo'
            onClickEdit={item =>
              props.history.push(`/transportadoras/${item.id}`)
            }
            onClickNew={() => props.history.push('/transportadoras/new')}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApi>
  )
}

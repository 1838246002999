import { removeUndefinedValues } from '../../../utils/removeUndefinedValues'
import { formatBetweenDate } from './formatBetweenDate'

export const transformWhere = where => {
  const copy = { ...where }

  delete copy.data_entrada_de
  delete copy.data_entrada_ate

  delete copy.data_saida_de
  delete copy.data_saida_ate

  copy.data_entrada = formatBetweenDate(
    where.data_entrada_de,
    where.data_entrada_ate
  )
  copy.data_saida = formatBetweenDate(where.data_saida_de, where.data_saida_ate)

  return removeUndefinedValues(copy)
}

import { downloadXlsx } from '../../../utils/downloadXlsx'
import { formatDateTime } from '../../../utils/formatters'
import { centavosToRealStr } from '../../../utils/monetary'
import { formatOpcaoPagamento } from './formatOpcaoPagamento'

const fields = {
  id: {
    label: 'Id'
  },
  usuario: {
    label: 'Usuário'
  },
  placa: {
    label: 'Placa'
  },
  senha: {
    label: 'Senha'
  },
  data_entrada: {
    label: 'Data Entrada'
  },
  data_saida: {
    label: 'Data Saída'
  },
  data_cancelamento: {
    label: 'Data Cancelamento'
  },
  observacao: {
    label: 'Observação'
  },
  numero_eixo: {
    label: 'Número Eixo'
  },
  valor_real: {
    label: 'Valor',
    format: value =>
      typeof value === 'number' ? centavosToRealStr(value) : value
  },
  cliente: {
    label: 'Cliente'
  },
  opcao_pagamento: {
    label: 'Método de Cobrança',
    format: value => formatOpcaoPagamento(value)
  }
}

export const onDownloadClick = async list => {
  const date = formatDateTime(new Date())

  await downloadXlsx({
    list,
    listOptions: { fields },
    flatOnDownload: true,
    convertFormatAndDates: true,
    withFormatPassword: false,
    name: `Relatório de caminhões - ${date}`
  })
}

import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const styles = theme => ({
  card: {
    width: 128,
    marginRight: 38,
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  button: {
    margin: '8px 0'
  },
  divider: {
    marginTop: -32
  },
  caminhoesContent: {
    padding: '4px 8px',
    marginTop: 16,
    height: 480,
    backgroundColor: '#F6F9FB'
  },
  icon: {
    fontSize: 12,
    marginLeft: theme.spacing(1)
  },
  green: {
    color: 'green'
  },
  orange: {
    color: theme.palette.primary.main
  }
})

function FilaCard ({ classes, fila, onClose, ...props }) {
  const user = JSON.parse(localStorage.user)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    onClose(fila.id)
    fila.ativa = false
    setAnchorEl(null)
  }

  const renderCaminhoes = caminhoes => {
    return caminhoes.map(caminhao => (
      <Button
        variant='contained'
        fullWidth
        color={caminhao.data_chamada ? 'primary' : 'default'}
        size='small'
        key={caminhao.id}
        disabled={user.realm === 'destino'}
        className={classes.button}
        onClick={() => props.history.push(`caminhoes/${caminhao.id}`)}
      >
        {caminhao.senha.split('-')[1]}
      </Button>
    ))
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        action={ fila.ativa &&
          (<IconButton aria-label='settings' size='small' onClick={handleClick}>
            <MoreHoriz fontSize='small' />
          </IconButton>)
        }
        title={
          <div>
            Fila {fila.numero}
            <FiberManualRecordIcon
              className={`${classes.icon} ${
                fila.ativa ? classes.green : classes.orange
              }`}
            />
          </div>
        }
        titleTypographyProps={{ variant: 'button' }}
        subheader={fila.regraFila ? fila.regraFila.sigla : 'Livre'}
        subheaderTypographyProps={{ color: 'primary', variant: 'overline' }}
      />
      <CardContent>
        <Divider variant='middle' className={classes.divider} />
        <div className={classes.caminhoesContent}>
          {renderCaminhoes(fila.caminhoes)}
        </div>
      </CardContent>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      > <MenuItem onClick={handleClose}>Fechar Fila</MenuItem>
      </Menu>
    </Card>
  )
}

export default withStyles(styles)(FilaCard)

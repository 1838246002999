import Grid from '@material-ui/core/Grid'
import { Checkbox, CrudForm, FormApi } from 'elentari'
import { TextField } from 'final-form-material-ui'
import React from 'react'
import { Field } from 'react-final-form'
import NumberFormat from 'react-number-format'
import { tabelaPrecoApi } from '../../services/Api'
import { centavosToRealNumber, realToCentavos } from '../../utils/monetary'

const totalEixosTabela = 9

function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={2}
      isNumericString
      prefix='R$'
    />
  )
}

export function TabelaPrecoForm (props) {
  const validate = values => {
    let valorEixos = {}
    for (let eixo = 1; eixo <= totalEixosTabela; eixo++) {
      const valueEixo = `valor_${eixo}_eixo`
      valorEixos = {
        ...valorEixos,
        [valueEixo]: values[valueEixo] >= 0 ? undefined : 'Obrigatório'
      }
    }
    return {
      descricao:
        values.descricao && values.descricao.trim() ? undefined : 'Obrigatório',
      ...valorEixos
    }
  }

  const transformValuesEixos = (data, functionTransform) => {
    let itemFormat = {}
    for (let eixo = 1; eixo <= totalEixosTabela; eixo++) {
      const valorEixo = `valor_${eixo}_eixo`
      itemFormat = {
        ...data,
        ...itemFormat,
        [valorEixo]: functionTransform(data[valorEixo])
      }
    }
    return itemFormat
  }

  const getItemDecorator = async ({ getItem }) => {
    const item = await getItem()
    return transformValuesEixos(item, centavosToRealNumber)
  }

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const dataFormat = transformValuesEixos(data, realToCentavos)
    return await handleSubmit({
      id,
      data: dataFormat
    })
  }

  const componentsEixos = () => {
    const componentsEixosArray = []
    for (let eixo = 1; eixo <= totalEixosTabela; eixo++) {
      const label = `${eixo} Eixo${eixo > 1 ? 's' : ''}`
      const name = `valor_${eixo}_eixo`
      componentsEixosArray.push(
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            component={TextField}
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
            label={label}
            name={name}
            minimumValue='0'
          >
            {props => <TextField {...props} />}
          </Field>
        </Grid>
      )
    }
    return componentsEixosArray
  }

  return (
    <FormApi
      initialData={{ ativo: true }}
      api={tabelaPrecoApi}
      submitDecorator={submitDecorator}
      getItemDecorator={getItemDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item sm={8} xs={12}>
              <Field
                fullWidth
                component={TextField}
                label='Descrição'
                name='descricao'
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Ativo'
                name='ativo'
              />
            </Grid>
            {componentsEixos()}
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import React, {useEffect, useState} from 'react'
import * as R from 'ramda'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { Field, Form } from 'react-final-form'
import { Grid } from '@material-ui/core'
import { filaApi } from '../../services/Api'
import {
  autocompleteHelpers,
  Select,
} from 'elentari'
const { toOption } = autocompleteHelpers

export default function SimpleFormDialog (props) {
  const [filas, setFilas] = useState([])

  const {
    open = false,
    handleClose = R.empty,
    primaryAction = (first, second) => R.empty(),
    defaultObservacao = '',
    title = ''
  } = props


  const handlePrimaryAction = data => {
    primaryAction(data)
    handleClose()
  }

  useEffect(() => {
    const fetchOpcoesFila = async (caminhao) => {
      const filasResponse = await filaApi.filasDisponiveis({
        id_regra_fila: caminhao.id_regra_fila
      })

      if (filasResponse.ok) {
        let { outrasOpcoes } = filasResponse.data
        if (outrasOpcoes.length > 0) {
          outrasOpcoes = outrasOpcoes.map(fila => ({
            ...fila,
            numero: `${fila.patio} - Fila: ${
              fila.numero
            } * - Quantidade caminhões: ${fila.caminhoes}`
          }))
        }
        setFilas(outrasOpcoes)
      }
    }
    if (props.caminhao) {
      fetchOpcoesFila(props.caminhao)
    }
  }, [props.caminhao])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <>
            <Typography variant='body1' gutterBottom>
              Só é possível alterar um caminhão para filas vazias e sem regras.
            </Typography>
            <Form
              onSubmit={handlePrimaryAction}
              initialValues={{ observacao: defaultObservacao }}
            >
              {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                  >
                    <Grid item sm={12}>
                      <Field
                        fullWidth
                        hideEmpty
                        component={Select}
                        options={filas.map(toOption('numero'))}
                        defaultValue={filas.length && filas[0].id}
                        label='Fila'
                        name='fila'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid justify='flex-end' container spacing={2}>
                        <Grid item>
                          <Button fullWidth onClick={handleClose}>
                            Voltar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            fullWidth
                            type='submit'
                            disabled={submitting}
                            variant='contained'
                            color='primary'
                          >
                            Salvar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </>
        </DialogContent>
      </Dialog>
    </div>
  )
}

import React from 'react'
import * as R from 'ramda'
import Button from '@material-ui/core/Button'
import { TextField } from 'final-form-material-ui'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Field, Form } from 'react-final-form'
import { withStyles, Typography } from '@material-ui/core'

const styles = theme => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  textCenter: {
    textAlign: 'center'
  }
})

const CaixaInfoDialog = props => {
  const {
    open = false,
    handleClose = R.empty,
    primaryAction = (first, second) => R.empty(),
    classes
  } = props

  const handlePrimaryAction = data => {
    primaryAction(data)
    handleClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle classes={{root: classes.textCenter}}>Informações Extras</DialogTitle>
        <DialogContent>
          <Typography variant='body1' gutterBottom>
            Caso necessário descreva informações sobre o fechamento do caixa.
          </Typography>
          <Form onSubmit={handlePrimaryAction}>
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  fullWidth
                  component={TextField}
                  multiline={true}
                  defaultValue=''
                  label='Informações'
                  name='info'
                />
                <div className={classes.btnContainer}>
                  <Button onClick={handleClose}>Cancelar</Button>
                  <Button
                    type='submit'
                    disabled={submitting}
                    variant='contained'
                    color='primary'
                    className={classes.marginLeft}
                  >
                    Confirmar Fechamento
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default withStyles(styles)(CaixaInfoDialog)

import React, { useState, useEffect } from 'react'
import { Grid, withStyles, Fab } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CompareArrows from '@material-ui/icons/CompareArrows'
import {
  regraFilaApi,
  destinoApi,
  produtoApi,
  caminhaoApi
} from '../../services/Api'
import ListaCaminhoes from './ListaCaminhoes'

const styles = theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  menu: {
    position: 'fixed',
    height: '100%',
    overflowY: 'inherit',
    width: 200,
    marginTop: -24,
    marginLeft: -24,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    color: '#FFF',
    borderTopRightRadius: 20
  },
  content: {
    marginLeft: 224,
    padding: 24
  },
  checkbox: {
    color: '#FFF'
  },
  divider: {
    marginTop: 8,
    backgroundColor: '#FFF'
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4)
  }
})

const WhiteCheckbox = withStyles({
  root: {
    color: 'white'
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />)

function ChaminhoesChamada ({ classes, ...props }) {
  const [destinos, setDestinos] = useState([])
  const [regras, setRegras] = useState([])
  const [produtos, setProdutos] = useState([])
  const [emAndamento, setEmAndamento] = useState([])
  const [loading, setLoading] = useState(false)
  const [filtro, setFiltro] = useState({
    regras: [],
    destinos: [],
    produtos: [],
    order: 'asc'
  })

  useEffect(() => {
    async function fetchData () {
      const [
        responseRegras,
        responseProdutos,
        responseDestinos
      ] = await Promise.all([
        regraFilaApi.chamada({ filtro }),
        produtoApi.chamada({ filtro }),
        destinoApi.chamada({ filtro })
      ])
      if (responseRegras.ok) {
        setRegras(responseRegras.data)
      }
      if (responseProdutos.ok) {
        setProdutos(responseProdutos.data)
      }
      if (responseDestinos.ok) {
        setDestinos(responseDestinos.data)
      }
      const responseAndamento = await caminhaoApi.getList({
        where: {
          and: [{ data_chamada: { neq: null } }, { data_saida: null }]
        }
      })
      if (responseAndamento.ok) {
        const ids = responseAndamento.data.map(c => c.id)
        setEmAndamento(ids)
      }
    }
    fetchData()
  }, [props.history.location.pathname, filtro])

  const handleChange = (tipo, id) => event => {
    if (event.target.checked) {
      return setFiltro({ ...filtro, [tipo]: filtro[tipo].concat(id) })
    } else {
      return setFiltro({
        ...filtro,
        [tipo]: filtro[tipo].filter(i => i !== id)
      })
    }
  }

  const renderDestinos = () => {
    return (
      <Grid item xs={12}>
        <Typography variant='h5' gutterBottom color='inherit'>
          Destinos
        </Typography>
        <FormGroup>
          {destinos.map(destino => (
            <FormControlLabel
              key={destino.id}
              control={
                <WhiteCheckbox
                  disabled={loading}
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  onChange={handleChange('destinos', destino.id)}
                />
              }
              label={destino.descricao}
              classes={{ label: classes.checkbox }}
            />
          ))}
        </FormGroup>
        <Divider variant='middle' classes={{ root: classes.divider }} />
      </Grid>
    )
  }

  const renderProdutos = () => {
    return (
      <Grid item xs={12}>
        <Typography variant='h5' gutterBottom color='inherit'>
          Produtos
        </Typography>
        <FormGroup>
          {produtos.map(produto => (
            <FormControlLabel
              key={produto.id}
              control={
                <WhiteCheckbox
                  disabled={loading}
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  onChange={handleChange('produtos', produto.id)}
                />
              }
              label={produto.descricao}
              classes={{ label: classes.checkbox }}
            />
          ))}
        </FormGroup>
        <Divider variant='middle' classes={{ root: classes.divider }} />
      </Grid>
    )
  }

  const renderRegras = () => {
    return (
      <Grid item xs={12}>
        <Typography variant='h5' gutterBottom color='inherit'>
          Regras
        </Typography>
        <FormGroup>
          {regras.map(regra => (
            <FormControlLabel
              key={regra.id}
              control={
                <WhiteCheckbox
                  disabled={loading}
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  onChange={handleChange('regras', regra.id)}
                />
              }
              label={regra.sigla}
              classes={{ label: classes.checkbox }}
            />
          ))}
        </FormGroup>
      </Grid>
    )
  }

  const onClickAndamento = () => {
    props.history.push(`/chamada/iniciar?ids=${emAndamento}`)
  }

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
          spacing={2}
        >
          {renderDestinos()}
          {renderProdutos()}
          {renderRegras()}
        </Grid>
      </div>
      <div className={classes.content}>
        <ListaCaminhoes filtro={filtro} setLoading={setLoading} {...props} />
        {emAndamento.length ? (
          <Fab
            color='primary'
            aria-label='Em Andamento'
            className={classes.fab}
            onClick={onClickAndamento}
          >
            <CompareArrows />
          </Fab>
        ) : null}
      </div>
    </div>
  )
}

export default withStyles(styles)(ChaminhoesChamada)

import { Box, Button, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { caminhaoApi } from '../../services/Api'
import { onDownloadClick } from './utils/onDownloadClick'
import { formatValues } from './utils/formatValues'
import { useLocation } from 'react-router-dom'
import { transformWhere } from './utils/transformWhere'
import { Snackbar, getWhereFilter } from 'elentari'
import { filtersConfig } from './utils/filtersConfig'

const urlFilterToObject = filter => {
  if (!filter) return {}
  return filter.split(',').reduce((obj, keyValue) => {
    const [key, value] = keyValue.split('=')
    return { ...obj, [key]: value }
  }, {})
}

export function DownloadList () {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [mensagem, setMensagem] = useState('')

  async function downloadList () {
    const params = new URLSearchParams(location.search)

    try {
      setLoading(true)
      const filter = urlFilterToObject(params.get('filters'))
      const order = params.get('order')

      const response = await caminhaoApi.getList({
        where: getWhereFilter(transformWhere(filter), {}, filtersConfig),
        order
      })

      if (response.ok) {
        const values = await formatValues(response.data)
        onDownloadClick(values)
      } else {
        setMensagem('Houve um problema ao baixar a lista, tente novamente.')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSnackbarClose = () => {
    setMensagem('')
  }

  return (
    <>
      <Button variant='extended' onClick={downloadList} disabled={loading}>
        <Box display='flex' alignItems='center' justifyContent='center' mr={1}>
          {loading ? <CircularProgress size={20} /> : <DownloadIcon />}
        </Box>
        <Box>Baixa Lista</Box>
      </Button>
      <Snackbar
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={mensagem}
      />
    </>
  )
}

import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { destinoApi } from '../../services/Api'

export function DestinoSelect ({ setDestinoId, value, disabled = false }) {
  const [destinos, setDestinos] = useState([])
  const [selected, setSelected] = useState('')

  async function fetchDestinos (filter = {}) {
    const response = await destinoApi.getList(filter)
    if (response.ok) {
      setDestinos(response.data)
    }
  }

  useEffect(() => {
    fetchDestinos()
    if (value) {
      setSelected(value)
    }
  }, [value])

  const handleChange = event => {
    const destinoId = event.target.value
    setSelected(destinoId)
    if (setDestinoId) {
      setDestinoId(destinoId)
    }
  }

  return (
    <TextField
      id='select-destino'
      select
      fullWidth
      disabled={disabled}
      value={selected}
      onChange={handleChange}
      label='Destino'
      variant='outlined'
    >
      <MenuItem key={0} value=''>
        Todos
      </MenuItem>
      {destinos.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.descricao}
        </MenuItem>
      ))}
    </TextField>
  )
}

import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {
  Autocomplete,
  Checkbox,
  CrudForm,
  FormApi,
  MaskedField,
  MountEntity,
  autocompleteHelpers
} from 'elentari'
import { TextField } from 'final-form-material-ui'
import React from 'react'
import { Field } from 'react-final-form'
import { clienteApi, tabelaPrecoApi } from '../../services/Api'
import { isCNPJ } from '../../utils/isCNPJ'
import { loadTabelaPrecos } from '../TabelaPrecos'
import { cnpjRegex } from './utils'
const { toOption } = autocompleteHelpers

export function ClienteForm (props) {
  const fieldsRequired = (efetuarConsultaAntt, fieldValue) => {
    return efetuarConsultaAntt && !fieldValue?.trim()
      ? 'Obrigatório'
      : undefined
  }

  const validate = values => ({
    nome: values.nome && values.nome.trim() ? undefined : 'Obrigatório',
    tabela_preco: values.tabela_preco ? undefined : 'Obrigatório',
    rntrc: fieldsRequired(values.efetuar_consulta_antt, values.rntrc),
    cnpj:
      fieldsRequired(values.efetuar_consulta_antt, values.cnpj) ||
      (values.cnpj && !isCNPJ(values.cnpj))
        ? 'CNPJ inválido'
        : undefined
  })

  const getItemDecorator = async ({ getItem }) => {
    const result = await MountEntity(props, clienteApi.getOne)
      .populate(({ data }) => ({
        tabela_preco: tabelaPrecoApi.getOne(data.id_tabela_preco)
      }))
      .clean(['id_tabela_preco'])
      .map(data => ({
        ...data,
        tabela_preco: toOption('descricao')(data.tabela_preco)
      }))
      .getItem()
    return result
  }

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        efetuar_consulta_antt: data.efetuar_consulta_antt || false,
        status: data.status || false,
        rntrc: data.rntrc || null,
        cnpj: data.cnpj || null,
        pagamento_vista: data.pagamento_vista || false,
        faturamento: data.faturamento || false,
        id_tabela_preco: data.tabela_preco.value
      }
    })
    return result
  }

  return (
    <FormApi
      initialData={{ status: true }}
      api={clienteApi}
      submitDecorator={submitDecorator}
      getItemDecorator={getItemDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Field fullWidth component={TextField} label='Nome' name='nome' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                label='Tabela de Preço'
                loadOptions={loadTabelaPrecos}
                component={Autocomplete}
                name='tabela_preco'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Efetuar Consulta ANTT'
                name='efetuar_consulta_antt'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                component={TextField}
                label='RNTRC'
                name='rntrc'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                component={MaskedField}
                label='CNPJ'
                name='cnpj'
                mask={cnpjRegex}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Ativo'
                name='status'
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5'>Formas de Pagamento</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='À vista'
                name='pagamento_vista'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Faturamento'
                name='faturamento'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { centavosToRealStr } from '../../../utils/monetary'
import { operacaoCaixaApi } from '../../../services/Api'
import moment from 'moment'
import { splitEvery } from 'ramda'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  card: {
    padding: theme.spacing(2),
    height: 250
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  number: {
    marginTop: 75
  }
})

const getURLFilter = search => {
  const searchParams = new URLSearchParams(search)
  const filters = searchParams.get('filters')
  if (!filters) return {}
  return splitEvery(2, filters.split(/[,=]/g))
    .filter(([key, value]) => value !== 'null' && value !== 'undefined')
    .reduce(
      (values, [key, value]) => ({
        ...values,
        [key]: value
      }),
      {}
    )
}

const transformFilter = filter => {
  switch (filter.visualizacao) {
    case 'diario':
      filter.data_inicio = moment(filter.dia).format()
      filter.data_fim = moment(filter.dia)
        .add(1, 'day')
        .format()
      delete filter.dia
      break
    case 'mensal':
      filter.data_inicio = moment(filter.mes).format()
      filter.data_fim = moment(filter.mes)
        .add(1, 'month')
        .format()
      delete filter.mes
      break
    case 'anual':
      filter.data_inicio = moment(filter.ano).format()
      filter.data_fim = moment(filter.ano)
        .add(1, 'year')
        .format()
      delete filter.ano
      break
    default:
  }
  delete filter.visualizacao
  return filter
}

const OperacoesCards = props => {
  const { classes } = props
  const [loading, setLoading] = useState(false)
  const [entradasCount, setEntradasCount] = useState()
  const [entradasSum, setEntradasSum] = useState()
  const [sangriasSum, setSangriasSum] = useState()
  const [suprimentosSum, setSuprimentosSum] = useState()

  const fetchData = async filter => {
    setLoading(true)
    try {
      const [
        countResponse,
        entradasResponse,
        sangriasResponse,
        suprimentosResponse,
        estornosResponse
      ] = await Promise.all([
        operacaoCaixaApi.getCount({
          where: {
            and: [
              { tipo: 'Entrada' },
              { id_destino: filter.id_destino },
              { id_cliente: filter.id_cliente },
              {
                data: {
                  between: [filter.data_inicio, filter.data_fim]
                }
              }
            ]
          }
        }),
        operacaoCaixaApi.sumValor({ ...filter, tipo: 'Entrada' }),
        operacaoCaixaApi.sumValor({
          ...filter,
          tipo: 'Sangria',
          id_cliente: null,
          id_destino: null
        }),
        operacaoCaixaApi.sumValor({
          ...filter,
          tipo: 'Suprimento',
          id_cliente: null,
          id_destino: null
        }),
        operacaoCaixaApi.sumValor({
          ...filter,
          tipo: 'Estorno',
          id_cliente: null,
          id_destino: null
        })
      ])

      if (countResponse.ok) {
        setEntradasCount(countResponse.data.count)
      }

      if (entradasResponse.ok) {
        setEntradasSum(entradasResponse.data.sum)
      }

      if (sangriasResponse.ok) {
        setSangriasSum(sangriasResponse.data.sum)
      }

      let suprimentos = 0
      if (suprimentosResponse.ok) {
        suprimentos = suprimentosResponse.data.sum
      }

      if (estornosResponse.ok) {
        const estornos = Math.abs(estornosResponse.data.sum)
        setSuprimentosSum(suprimentos - estornos)
      } else {
        setSuprimentosSum(suprimentos)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const search = props.history.location.search

    const filter = getURLFilter(search)
    setEntradasSum()
    setSangriasSum()
    setSuprimentosSum()
    fetchData(transformFilter(filter))
  }, [props.history.location.search])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6} sm={12}>
        <Card className={classes.card}>
          <Typography variant='body2' className={classes.title}>
            Total de entradas
          </Typography>
          {
            <Typography variant='h2' align='center' className={classes.number}>
              <OperacoesFieldValue value={entradasCount} loading={loading} />
            </Typography>
          }
        </Card>
      </Grid>
      <Grid item xs={12} lg={6} sm={12}>
        <Card className={classes.card}>
          <Typography variant='body2' className={classes.title}>
            Valor total das entradas
          </Typography>
          <Typography variant='h2' align='center' className={classes.number}>
            <OperacoesFieldValue
              value={entradasSum && centavosToRealStr(entradasSum)}
              loading={loading}
            />
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6} sm={12}>
        <Card className={classes.card}>
          <Typography variant='body2' className={classes.title}>
            Valor total das sangrias
          </Typography>
          <Typography variant='h2' align='center' className={classes.number}>
            <OperacoesFieldValue
              value={sangriasSum && centavosToRealStr(sangriasSum)}
              loading={loading}
            />
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6} sm={12}>
        <Card className={classes.card}>
          <Typography variant='body2' className={classes.title}>
            Valor total dos suprimentos
          </Typography>
          <Typography variant='h2' align='center' className={classes.number}>
            <OperacoesFieldValue
              value={suprimentosSum && centavosToRealStr(suprimentosSum)}
              loading={loading}
            />
          </Typography>
        </Card>
      </Grid>
    </Grid>
  )
}

const OperacoesFieldValue = props => {
  if (props.loading)
    return <CircularProgress style={{ color: 'gray' }} size={26} />

  return <>{props.value}</>
}

export default withStyles(styles)(OperacoesCards)

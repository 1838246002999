import moment from 'moment'
import { splitEvery } from 'ramda'

export const getURLFilter = search => {
  const searchParams = new URLSearchParams(search)
  const filters = searchParams.get('filters')
  if (!filters) return {}
  return splitEvery(2, filters.split(/[,=]/g))
    .filter(([key, value]) => value !== 'null' && value !== 'undefined')
    .reduce(
      (values, [key, value]) => ({
        ...values,
        [key]: value
      }),
      {}
    )
}

export const transformFilter = filter => {
  switch (filter.visualizacao) {
    case 'diario':
      filter.group = 'hour'
      filter.data_inicio = moment(filter.dia).format()
      filter.data_fim = moment(filter.dia)
        .add(1, 'day')
        .format()

      delete filter.dia
      break
    case 'mensal':
      filter.group = 'day'
      filter.data_inicio = moment(filter.mes).format()
      filter.data_fim = moment(filter.mes)
        .add(1, 'month')
        .format()
      delete filter.mes
      break
    case 'anual':
      filter.group = 'month'
      filter.data_inicio = moment(filter.ano).format()
      filter.data_fim = moment(filter.ano)
        .add(1, 'year')
        .format()
      delete filter.ano
      break
    default:
  }
  delete filter.visualizacao
  return filter
}

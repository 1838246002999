import React from 'react'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import FormHelperText from '@material-ui/core/FormHelperText'

export default function CurrencyField ({
  input,
  label,
  currencySymbol = 'R$',
  decimal = ',',
  group = '.',
  meta,
  minimumValue = Number.MIN_SAFE_INTEGER.toString(),
  ...props
}) {
  return (
    <div>
      <CurrencyTextField
        {...props}
        label={label}
        value={input.value}
        currencySymbol={currencySymbol}
        error={meta.touched && !!meta.error}
        decimalCharacter={decimal}
        minimumValue={minimumValue}
        digitGroupSeparator={group}
        onChange={(e, value) => input.onChange(value)}
        textAlign='left'
      />
      <FormHelperText error hidden={!meta.touched}>
        {meta.error}
      </FormHelperText>
    </div>
  )
}

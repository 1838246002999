import {
  impressoraApi,
  templateApi,
  usuarioApi,
  caixaApi
} from '../services/Api'
import {
  realToCentavos,
  centavosToRealStr,
  calcularOperacoes
} from './monetary'
import moment from 'moment'

const getTemplate = id => {
  return templateApi.getOne(id)
}

function replaceAll (str, search, replacement) {
  return str.split(search).join(replacement)
}

const usuario = () => {
  const user = JSON.parse(localStorage.user)
  return user.name
}

export const imprimirAberturaCaixa = async data => {
  const response = await getTemplate('ABRIR_CAIXA')
  if (response.ok) {
    const templateStr = JSON.stringify(response.data)
    const template = JSON.parse(replaceAll(templateStr, /\\\\/g, '\\'))
    return impressoraApi.imprimir({
      action: template.id,
      info: {
        cabecalho: template.cabecalho,
        titulo: template.titulo,
        data_abertura: moment(data.data_abertura).format('DD/MM/YYYY HH:mm:ss'),
        valor_abertura: centavosToRealStr(realToCentavos(data.valor_abertura)),
        usuario: usuario(),
        texto: template.texto
      }
    })
  }
  return {
    ok: false
  }
}

export const imprimirFechamentoCaixa = async data => {
  const response = await getTemplate('FECHAR_CAIXA')
  if (response.ok) {
    const templateStr = JSON.stringify(response.data)
    const template = JSON.parse(replaceAll(templateStr, /\\\\/g, '\\'))
    const {
      entradas,
      sangrias,
      suprimentos,
      canceladas,
      estornos
    } = calcularOperacoes(data.operacoesCaixa)
    const valor_abertura = realToCentavos(data.valor_abertura)
    const total = valor_abertura + entradas + suprimentos - estornos
    return impressoraApi.imprimir({
      action: template.id,
      info: {
        cabecalho: template.cabecalho,
        titulo: template.titulo,
        data_abertura: moment(data.data_abertura).format('DD/MM/YYYY HH:mm:ss'),
        data_fechamento: moment(data.data_fechamento).format(
          'DD/MM/YYYY HH:mm:ss'
        ),
        valor_abertura: centavosToRealStr(valor_abertura),
        entradas: centavosToRealStr(entradas),
        total: centavosToRealStr(total),
        sangrias: centavosToRealStr(sangrias),
        suprimentos: centavosToRealStr(suprimentos - estornos),
        canceladas: centavosToRealStr(canceladas),
        saldo: data.saldo,
        usuario: usuario(),
        texto: template.texto
      }
    })
  }
  return {
    ok: false
  }
}

export const reimprimirFechamentoCaixa = async data => {
  const response = await getTemplate('FECHAR_CAIXA')
  if (response.ok) {
    const templateStr = JSON.stringify(response.data)
    const template = JSON.parse(replaceAll(templateStr, /\\\\/g, '\\'))
    const {
      entradas,
      sangrias,
      suprimentos,
      canceladas,
      estornos
    } = calcularOperacoes(data.operacoesCaixa)
    const valor_abertura = realToCentavos(data.valor_abertura)
    const total = valor_abertura + entradas + suprimentos - estornos
    return impressoraApi.imprimir({
      action: template.id,
      info: {
        cabecalho: template.cabecalho,
        titulo: `*** REIMPRESSAO ***\n${template.titulo}`,
        data_abertura: moment(data.data_abertura).format('DD/MM/YYYY HH:mm:ss'),
        data_fechamento: moment(data.data_fechamento).format(
          'DD/MM/YYYY HH:mm:ss'
        ),
        valor_abertura: centavosToRealStr(valor_abertura),
        entradas: centavosToRealStr(entradas),
        total: centavosToRealStr(total),
        sangrias: centavosToRealStr(sangrias),
        suprimentos: centavosToRealStr(suprimentos - estornos),
        canceladas: centavosToRealStr(canceladas),
        saldo: data.saldo,
        usuario: usuario(),
        texto: template.texto
      }
    })
  }
  return {
    ok: false
  }
}

export const imprimirTicketSangria = async data => {
  const [reponseUsuario, responseCaixa] = await Promise.all([
    usuarioApi.getOne(data.id_usuario),
    caixaApi.getOne(data.id_caixa)
  ])
  const response = await getTemplate('TICKETSSANGRIA')
  if (response.ok && reponseUsuario.ok && responseCaixa.ok) {
    const templateStr = JSON.stringify(response.data)
    const template = JSON.parse(replaceAll(templateStr, /\\\\/g, '\\'))
    return impressoraApi.imprimir({
      action: 'FECHAR_CAIXA', // Usando action do FECHAR_CAIXA
      info: {
        cabecalho: template.cabecalho,
        titulo: template.titulo,
        caixa: responseCaixa.data.nome,
        usuario: reponseUsuario.data.username,
        usuarioLogado: usuario(),
        valor: centavosToRealStr(-data.valor),
        data: moment(data.data).format('DD/MM/YYYY HH:mm:ss'),
        texto: template.texto
      }
    })
  }
  return {
    ok: false
  }
}

export const imprimirTickets = async data => {
  const response = await getTemplate('TICKETS')
  if (response.ok) {
    const templateStr = JSON.stringify(response.data)
    const template = JSON.parse(replaceAll(templateStr, /\\\\/g, '\\'))
    return impressoraApi.imprimir({
      action: template.id,
      info: {
        cabecalho: template.cabecalho,
        titulo: template.titulo,
        data_entrada: moment(data.data_entrada).format('DD/MM/YYYY HH:mm:ss'),
        placa: data.placa,
        numero_eixo: data.numero_eixo,
        produto: data.produto,
        fila: data.fila,
        nota: data.nota,
        valor: centavosToRealStr(data.valor),
        usuario: usuario(),
        senha: data.senha,
        texto: template.texto,
        regras: template.extra
      }
    })
  }
  return {
    ok: false
  }
}

export const reimprimirTicket = async (data, regra = false) => {
  const response = await getTemplate('TICKETS')
  if (response.ok) {
    const templateStr = JSON.stringify(response.data)
    const template = JSON.parse(replaceAll(templateStr, /\\\\/g, '\\'))
    return impressoraApi.imprimir(
      JSON.stringify({
        action: 'REIMPRIMIR_TICKET',
        info: {
          cabecalho: template.cabecalho,
          titulo: `*** REIMPRESSAO ***\n${template.titulo}`,
          data_entrada: moment(data.data_entrada).format('DD/MM/YYYY HH:mm:ss'),
          placa: data.placa,
          numero_eixo: data.numero_eixo,
          produto: data.produto,
          fila: data.fila,
          nota: data.nota,
          valor: centavosToRealStr(data.valor),
          usuario: usuario(),
          senha: data.senha,
          texto: template.texto,
          regras: regra ? template.extra : undefined
        }
      })
    )
  }
  return {
    ok: false
  }
}

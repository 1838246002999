export const removeUndefinedValues = obj => {
  for (const att of Object.keys(obj)) {
    const value = obj[att]

    if (value === 'undefined') {
      delete obj[att]
    }
  }

  return obj
}

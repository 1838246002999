import React from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { caminhaoApi } from '../../../services/Api'
import { ListApi, List } from 'elentari'
import moment from 'moment'

const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  number: {
    marginTop: 75
  }
})

const TempoCaminhoesCard = props => {
  const { classes } = props

  const listOptions = {
    defaultOrder: 'data_entrada ASC',
    fields: {
      data_entrada: {
        label: 'Entrada',
        format: date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')
      },
      placa: {
        label: 'Placa'
      },
      senha: {
        label: 'Senha'
      }
    }
  }

  const transformWhere = where => {
    return {
      ...where,
      data_saida: null
    }
  }

  const getCountDecorator = getCount => async ({ filter, location }) => {
    return getCount({
      filter: {
        ...filter,
        data_saida: null
      },
      location
    })
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    return items
  }

  return (
    <Card className={classes.card}>
      <Typography variant='subtitle1' align='center' className={classes.title}>
        Caminhões parados a mais tempo
      </Typography>
      <ListApi
        api={caminhaoApi}
        transformWhere={transformWhere}
        getPageDecorator={getPageDecorator}
      >
        {({ getPage, getCount }) => (
          <div>
            <List
              {...props}
              hideDownloadIcon
              withPaper
              getPage={getPage}
              getCount={getCountDecorator(getCount)}
              listOptions={listOptions}
            />
          </div>
        )}
      </ListApi>
    </Card>
  )
}

export default withStyles(styles)(TempoCaminhoesCard)

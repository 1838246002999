import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { produtoApi } from '../../services/Api'
import { FormApi, CrudForm, Checkbox } from 'elentari'

export function ProdutoForm (props) {
  const validate = values => ({
    descricao:
      values.descricao && values.descricao.trim() ? undefined : 'Obrigatório',
    sigla: values.sigla && values.sigla.trim() ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data
      }
    })
    return result
  }

  return (
    <FormApi
      initialData={{ status: true }}
      api={produtoApi}
      submitDecorator={submitDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field
                fullWidth
                component={TextField}
                label='Descrição'
                name='descricao'
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                fullWidth
                component={TextField}
                label='Sigla'
                name='sigla'
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Ativo'
                name='status'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import moment from 'moment'

const dateFormat = date => {
  return moment(date).format('YYYY-MM-DD')
}

const dateTomorrow = date => {
  const tomorow = moment(date).add(1, 'days')
  return tomorow
}

export const formatBetweenDate = (dataDeValue, dataAteValue) => {
  if (!dataDeValue && !dataAteValue) return

  const dataDe = dataDeValue || dataAteValue
  const dataAte = dateTomorrow(dataAteValue || dataDeValue)

  const dateDeFormatted = dateFormat(dataDe)
  const dateAteFormatted = dateFormat(dataAte)

  return { between: [dateDeFormatted, dateAteFormatted] }
}

import { useState } from 'react'

export function useModalControl () {
  const [isOpen, setIsOpen] = useState(false)

  function handle (value) {
    setIsOpen(value)
  }

  function handleOpen () {
    handle(true)
  }

  function handleClose () {
    handle(false)
  }

  return { isOpen, handleOpen, handleClose }
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { filaApi, regraFilaApi, patioApi } from '../../services/Api'
import { loadPatios } from '../Patios'
import { ListApi, List, Filter, Select, Autocomplete } from 'elentari'
import { loadRegrasFila } from '../RegrasFila'

function FilasFilter (props) {
  return (
    <Filter
      formatFilters={{
        id_patio: value => value?.id,
        id_regra_fila: value => value?.id
      }}
      labels={{ find: 'Buscar', clear: 'Limpar' }}
    >
      <Grid spacing={3} container>
        <Grid item xs={12} sm={6} md={3}>
          <Field
            fullWidth
            component={TextField}
            label='Número'
            name='numero'
            type='number'
            min={1}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Field
            label='Regra'
            loadOptions={loadRegrasFila}
            component={Autocomplete}
            name='id_regra_fila'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Field
            label='Pátio'
            loadOptions={loadPatios}
            component={Autocomplete}
            name='id_patio'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Field
            fullWidth
            hideEmpty
            component={Select}
            options={[
              { label: 'Ativa', value: true },
              { label: 'Inativa', value: false }
            ]}
            label='Status'
            name='ativa'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function FilasList (props) {
  const user = JSON.parse(localStorage.user)
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      numero: {
        label: 'Número'
      },
      regra_fila: {
        label: 'Regra'
      },
      max_caminhoes: {
        label: 'Qtd Max caminhões'
      },
      ativa: {
        label: 'Ativa',
        format: boolean => (boolean ? 'Ativo' : 'Inativo')
      },
      patio: {
        label: 'Pátio'
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    if (items) {
      const promises = items.map(async item => {
        const [regraFilaResponse, patioResponse] = await Promise.all([
          regraFilaApi.getOne(item.id_regra_fila),
          patioApi.getOne(item.id_patio)
        ])
        if (patioResponse.ok) {
          item.patio = patioResponse.data.descricao
          item.id_patio = undefined
        }
        if (item.id_regra_fila) {
          if (regraFilaResponse.ok) {
            item.regra_fila = regraFilaResponse.data.sigla
            item.id_regra_fila = undefined
          }
        } else {
          item.regra_fila = 'Nenhuma'
        }
        return item
      })
      return Promise.all(promises)
    }
  }

  const handleDeleteDecorator = async (handleDeleteF, item) => {
    const result = await handleDeleteF(item)
    if (result.ok) {
      return result
    }
    return {
      ok: false,
      message: 'Não é possível excluir a fila, pois há caminhões associados.'
    }
  }

  return (
    <ListApi api={filaApi} getPageDecorator={getPageDecorator}>
      {({ getPage, getCount, handleDeleteF }) => {
        const actions = {}
        if (user.realm === 'gestor' || user.realm === 'admin') {
          actions.deleteFItem = i => handleDeleteDecorator(handleDeleteF, i)
        }
        return (
          <div>
            <FilasFilter />
            <List
              {...props}
              {...actions}
              hideDownloadIcon
              withPaper
              onClickEdit={item => props.history.push(`/filas/${item.id}`)}
              onClickNew={() => props.history.push('/filas/new')}
              getPage={getPage}
              getCount={getCount}
              listOptions={listOptions}
            />
          </div>
        )
      }}
    </ListApi>
  )
}

import Grid from '@material-ui/core/Grid'
import {
  Autocomplete,
  DatePicker,
  Filter,
  List,
  ListApi,
  loopback
} from 'elentari'
import { TextField } from 'final-form-material-ui'
import moment from 'moment'
import React from 'react'
import { Field } from 'react-final-form'
import { caixaApi, usuarioApi } from '../../services/Api'
import { centavosToRealStr } from '../../utils/monetary'
import { loadUsers } from '../Usuarios/Usuarios'
const { loopbackFilters } = loopback

function CaixasFilter (props) {
  return (
    <Filter
      formatFilters={{
        id_usuario_abertura: value => value?.id,
        id_usuario_fechamento: value => value?.id
      }}
      labels={{ find: 'Buscar', clear: 'Limpar' }}
    >
      <Grid spacing={3} container>
        <Grid item sm={4}>
          <Field fullWidth component={TextField} label='Nome' name='nome' />
        </Grid>
        <Grid item sm={2}>
          <Field
            label='Usuário Abertura'
            loadOptions={loadUsers}
            component={Autocomplete}
            name='id_usuario_abertura'
          />
        </Grid>
        <Grid item sm={2}>
          <Field
            label='Usuário Fechamento'
            loadOptions={loadUsers}
            component={Autocomplete}
            name='id_usuario_fechamento'
          />
        </Grid>
        <Grid item sm={2}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data de Abertura'
            name='data_abertura'
          />
        </Grid>
        <Grid item sm={2}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data de Fechamento'
            name='data_fechamento'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const CaixasList = props => {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      nome: {
        label: 'Nome'
      },
      usuario_abertura: {
        label: 'Usuário Abertura'
      },
      usuario_fechamento: {
        label: 'Usuário Fechamento'
      },
      data_abertura: {
        label: 'Data de abertura',
        format: date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')
      },
      valor_abertura: {
        label: 'Valor de abertura',
        format: valor => (valor ? centavosToRealStr(valor) : '-'),
        noOrder: true
      },
      data_fechamento: {
        label: 'Data de fechamento',
        format: date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')
      },
      valor_fechamento: {
        label: 'Valor de fechamento',
        format: valor => (valor ? centavosToRealStr(valor) : '-'),
        noOrder: true
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    const promises = items.map(async item => {
      const [resAbertura, resFechamento] = await Promise.all([
        usuarioApi.getOne(item.id_usuario_abertura),
        usuarioApi.getOne(item.id_usuario_fechamento)
      ])
      if (resAbertura.ok) {
        item.usuario_abertura = resAbertura.data.username
        item.id_usuario_abertura = undefined
      }
      if (resFechamento.ok) {
        item.usuario_fechamento = resFechamento.data.username
        item.id_usuario_fechamento = undefined
      }
      return item
    })
    return Promise.all(promises)
  }

  const transformWhere = where => {
    const copy = { ...where }
    delete copy.usuario
    delete copy.data_criacao
    delete copy.data_modificacao
    if (where.data_criacao) {
      const date = moment(where.data_criacao).format('YYYY-MM-DD')
      const tomorow = moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      copy.data_criacao = { between: [date, tomorow] }
    }
    if (where.data_modificacao) {
      const date = moment(where.data_modificacao).format('YYYY-MM-DD')
      const tomorow = moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      copy.data_modificacao = { between: [date, tomorow] }
    }
    return copy
  }

  return (
    <ListApi
      filtersConfig={{
        nome: loopbackFilters.ilike,
        data_abertura: loopbackFilters.regexp
      }}
      transformWhere={transformWhere}
      api={caixaApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount }) => (
        <div>
          <CaixasFilter />
          <List
            {...props}
            hideDownloadIcon
            onClickEdit={item => props.history.push(`/caixas/${item.id}`)}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApi>
  )
}

export default CaixasList

import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import { centavosToRealStr } from '../../utils/monetary'
import {
  operacaoCaixaApi,
  caminhaoApi,
  destinoApi,
  clienteApi
} from '../../services/Api'
import { ListApi, List } from 'elentari'
import moment from 'moment'

const styles = theme => ({
  root: {
    margin: '16px 100px 0px 100px'
  }
})

const OperacoesCaixaList = forwardRef(
  ({ classes, operacoes, ...props }, getListRef) => {
    const listRef = useRef()
    const listOptions = {
      defaultOrder: 'data DESC',
      fields: {
        data: {
          label: 'Data',
          format: date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')
        },
        descricao: {
          label: 'Tipo'
        },
        cliente: {
          label: 'Cliente'
        },
        destino: {
          label: 'Destino'
        },
        placa: {
          label: 'Caminhão'
        },
        senha: {
          label: 'Senha'
        },
        valor: {
          label: 'Valor',
          format: value => centavosToRealStr(value)
        }
      }
    }

    const getPageDecorator = async ({ getPage }) => {
      const items = await getPage()
      if (items) {
        const promises = items.map(async item => {
          if (item.id_destino && item.id_caminhao && item.id_cliente) {
            const [
              clienteResponse,
              destinoResponse,
              caminhaoResponse
            ] = await Promise.all([
              clienteApi.getOne(item.id_cliente),
              destinoApi.getOne(item.id_destino),
              caminhaoApi.getOne(item.id_caminhao)
            ])
            if (
              clienteResponse.ok &&
              destinoResponse.ok &&
              caminhaoResponse.ok
            ) {
              item.cliente = clienteResponse.data.nome
              item.destino = destinoResponse.data.descricao
              item.placa = caminhaoResponse.data.placa
              item.senha = caminhaoResponse.data.senha
            }
          } else {
            item.cliente = null
            item.destino = null
            item.placa = null
            item.senha = null
          }
          return item
        })
        return Promise.all(promises)
      }
    }

    const getCurrentId = params => {
      if (params.childId) {
        return params.childId
      }
      return params.id
    }

    const transformWhere = where => {
      return {
        ...where,
        id_caixa: getCurrentId(props.match.params)
      }
    }

    useImperativeHandle(getListRef, () => ({
      updatePage: () => {
        listRef.current.updatePage()
      }
    }))

    const getCountDecorator = getCount => async ({ filter, location }) => {
      return getCount({
        filter: {
          ...filter,
          id_caixa: getCurrentId(props.match.params)
        },
        location
      })
    }

    const handleRowClick = item => {
      if (item.id_caminhao) {
        props.history.push(`/caminhoes/${item.id_caminhao}`)
      }
    }

    return (
      <div className={classes.root}>
        <Typography variant='h6' gutterBottom>
          Operações de Caixa
        </Typography>
        <ListApi
          api={operacaoCaixaApi}
          transformWhere={transformWhere}
          getPageDecorator={getPageDecorator}
        >
          {({ getPage, getCount }) => (
            <List
              {...props}
              ref={listRef}
              withPaper
              getPage={getPage}
              getCount={getCountDecorator(getCount)}
              onClickRow={handleRowClick}
              listOptions={listOptions}
            />
          )}
        </ListApi>
      </div>
    )
  }
)

export default withStyles(styles)(OperacoesCaixaList)

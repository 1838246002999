import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { operacaoCaixaApi } from '../../../services/Api'
import { getURLFilter, transformFilter } from './FilterUtils'

const styles = theme => ({
  card: {
    padding: theme.spacing(2),
    height: 250
  },
  title: {
    marginBottom: theme.spacing(3)
  }
})

const QuantidadeEntradaChart = props => {
  const { classes } = props
  const [data, setData] = useState([])
  const [loaded, setLoaded] = useState(false)

  const fetchData = filter => {
    setLoaded(false)
    operacaoCaixaApi.countGrouped({ ...filter, tipo: 'Entrada' }).then(res => {
      if (res.ok) {
        setData(res.data)
        setLoaded(true)
      }
    })
  }

  useEffect(() => {
    const search = props.history.location.search
    const filter = getURLFilter(search)

    fetchData(transformFilter(filter))
  }, [props.history.location.search])

  return (
    <Card className={classes.card}>
      <Typography variant='body2' className={classes.title}>
        Quantidade de entradas
      </Typography>
      {loaded && (
        <ResponsiveContainer height={200}>
          <BarChart
            data={data}
            margin={{
              left: 40,
              top: 10
            }}
          >
            <YAxis />
            <Tooltip />
            <Legend iconType='circle' />
            <Bar
              dataKey='count'
              name='Quantidade de entradas'
              fill='#f96389'
              maxBarSize={30}
            />
            <XAxis dataKey='dateFormatted' />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Card>
  )
}

export default withStyles(styles)(QuantidadeEntradaChart)

import React, { useEffect } from 'react'
import { setToken, usuarioApi } from '../../services/Api'
import Loading from '../Components/Loading'

const Callback = () => {
  const login = async () => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const token = params.get('token')
    const redirectRoute = params.get('redirect_route')

    if (token) {
      const response = await usuarioApi.customLogin({ token })

      if (response.ok) {
        const { accessToken, usuario } = response.data
        const formattedUsuario = {
          ok: true,
          token: accessToken.id,
          name: usuario.username,
          realm: usuario.realm,
          id: usuario.id
        }

        setToken(accessToken.id)
        localStorage.setItem('user', JSON.stringify(formattedUsuario))
      }

      window.location.href = redirectRoute ?? '/'
    }
  }

  useEffect(() => {
    login()
  }, [])

  return <Loading />
}

export default Callback

import React, { useState, useEffect } from 'react'
import { Grid, withStyles, CircularProgress } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import qs from 'qs'
import {
  regraFilaApi,
  caminhaoApi
} from '../../services/Api'
import CaminhaoChamadaCard from './CaminhaoChamadaCard'

const styles = theme => ({
  root: {
  },
})


function ListaChamada({ classes, ...props }) {
  const [caminhoes, setCaminhoes] = useState(null)

  async function cancelarChamada(id) {
    const response = await caminhaoApi.cancelarChamada(id)
    if (response.ok) {
      setCaminhoes(caminhoes.filter(c => c.id !== id))
    }
  }
  async function confirmarChamada(id) {
    const response = await caminhaoApi.confirmarChamada(id)
    if (response.ok) {
      setCaminhoes(caminhoes.filter(c => c.id !== id))
    }
  }

  useEffect(() => {
    async function fetchCaminhoes() {
      const params = qs.parse(props.location.search.slice(1))
      const response = await caminhaoApi.getList({ where: { id: { inq: params.ids.split(',') } } })
      if (response.ok) {
        const invalido = response.data.some(c => !c.data_chamada || c.data_saida)
        if (invalido) {
          props.history.goBack()
        }
        const promises = response.data.map(async item => {
          const filter = { include: ['destino', 'produto'] }
          const response = await regraFilaApi.getOne(item.id_regra_fila, filter)
          if (response.ok) {
            item.regraFila = response.data
            return item
          }
          return item
        })
        setCaminhoes(await Promise.all(promises))
      }
    }
    fetchCaminhoes()
  }, [props.location, props.history])

  useEffect(() => {
    if (caminhoes && caminhoes.length === 0) {
      props.history.goBack()
    }
  }, [props.history, caminhoes])

  return ( caminhoes
    ? <Grid
      container
      direction='row'
      justify='flex-start'
      alignItems='flex-start'
      spacing={2}
      className={classes.root}>
      <Grid item sm={12}>
        <Tooltip title='Voltar'>
          <IconButton onClick={() => props.history.goBack()}
            aria-label='Voltar'>
            <ArrowBack />
          </IconButton>
        </Tooltip>
      </Grid>
        {caminhoes.map(caminhao => (
          <CaminhaoChamadaCard
            key={caminhao.id}
            caminhao={caminhao}
            onCancel={cancelarChamada}
            onConfirm={confirmarChamada} />
        ))}
    </Grid >
    : (
      <Grid container direction='row' justify='center' alignItems='center'>
        <CircularProgress />
      </Grid>
    ))
}

export default withStyles(styles)(ListaChamada)

import formatString from 'format-string-by-pattern'

export const parseCel = anyString => {
  const onlyNumbers = anyString.replace(/[^\d]/g, '')
  return formatString('(00) 00000-0000')(onlyNumbers)
}

export const parseFone = anyString => {
  const onlyNumbers = anyString.replace(/[^\d]/g, '')
  return formatString('(00) 0000-0000')(onlyNumbers)
}

export const parsePlaca = anyString => {
  return formatString('XXX-XXXX')(anyString)
}

import MomentUtils from '@date-io/moment'
import { withStyles } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Auth as AuthProvider, PrivateRoute, loginRoutes } from 'elentari'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.css'
import theme from './Theme'
import Home from './modules/Home'
import Callback from './modules/Login/Callback'
import Login from './modules/Login/Login'
import { setToken, usuarioApi } from './services/Api'

const styles = theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      height: '0.4em',
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
})

const verifyUser = async () => {
  const stringUser = localStorage.user
  if (stringUser) {
    const user = JSON.parse(stringUser)
    setToken(user.token)
    const response = await usuarioApi.authenticated()
    if (response.problem) {
      if (navigator.onLine) {
        delete localStorage.user
        document.location.reload()
      }
    }
  }
}

function App () {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AuthProvider withUser={() => verifyUser()}>
          <Router>
            <Switch>
              <Route path='/callback' component={Callback} />
              <Route path={loginRoutes.login} component={Login} />
              <PrivateRoute path='/' component={Home} />
            </Switch>
          </Router>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export default withStyles(styles)(App)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { clienteApi, tabelaPrecoApi } from '../../services/Api'
import {
  loopback,
  ListApi,
  List,
  Filter,
  Select,
  autocompleteHelpers,
  Autocomplete
} from 'elentari'
import { customHandleDeleteF } from '../../utils/elentari'
import { loadTabelaPrecos } from '../TabelaPrecos'
const { loopbackFilters } = loopback
const { toOption } = autocompleteHelpers

export const loadClientes = async inputValue => {
  const response = await clienteApi.getList({
    where: {
      nome: loopbackFilters.ilike(inputValue),
      status: true
    },
    order: 'nome asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('nome'))
  }
  return []
}

function ClientesFilter (props) {
  return (
    <Filter
      formatFilters={{ id_tabela_preco: value => value?.id }}
      labels={{ find: 'Buscar', clear: 'Limpar' }}
    >
      <Grid spacing={3} container>
        <Grid item sm={4}>
          <Field fullWidth component={TextField} label='Nome' name='nome' />
        </Grid>
        <Grid item sm={4}>
          <Field
            label='Tabela de Preço'
            loadOptions={loadTabelaPrecos}
            component={Autocomplete}
            name='id_tabela_preco'
          />
        </Grid>
        <Grid item sm={4}>
          <Field
            fullWidth
            hideEmpty
            component={Select}
            options={[
              { label: 'Ativo', value: true },
              { label: 'Inativo', value: false }
            ]}
            label='Status'
            name='status'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function ClientesList (props) {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      nome: {
        label: 'Nome'
      },
      tabela_preco: {
        label: 'Tabela de Preço',
        noOrder: true
      },
      status: {
        label: 'Status',
        format: boolean => (boolean ? 'Ativo' : 'Inativo')
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    if (items) {
      const promises = items.map(async item => {
        const responseTabelaPreco = await tabelaPrecoApi.getOne(
          item.id_tabela_preco
        )
        if (responseTabelaPreco.ok) {
          item.tabela_preco = responseTabelaPreco.data.descricao
          item.id_tabela_preco = undefined
        }
        return item
      })
      return Promise.all(promises)
    }
  }

  return (
    <ListApi
      filtersConfig={{
        nome: loopbackFilters.ilike
      }}
      api={clienteApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount }) => (
        <div>
          <ClientesFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            deleteFItem={customHandleDeleteF(clienteApi)}
            onClickEdit={item => props.history.push(`/clientes/${item.id}`)}
            onClickNew={() => props.history.push('/clientes/new')}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApi>
  )
}

import { formatDateTime } from './formatters'
import moment from 'moment'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'

function flatObject (obj) {
  const flatObject = {}
  const path = [] // current path

  function dig (obj) {
    if (obj !== Object(obj))
      return (flatObject[path.join('.')] = obj) /* <- value */
    for (const key in obj) {
      path.push(key)
      dig(obj[key])
      path.pop()
    }
  }
  dig(obj)
  return flatObject
}

const format = (key, obj, field = null, withFormatPassword) => {
  if (withFormatPassword && key.includes('senha')) {
    return '-'
  }
  if (moment(obj[key], moment.ISO_8601, true).isValid()) {
    return formatDateTime(obj[key])
  }
  if (field && field.format) {
    return field.format(obj[key])
  }
  return obj[key]
}

const createXlsx = async (
  list,
  listOptions,
  flatOnDownload,
  convertFormatAndDates,
  withFormatPassword,
  name,
  keyHeaders
) => {
  if (flatOnDownload) {
    list = list.map(obj => flatObject(obj))
  }
  const rows = list.map(obj => {
    if (keyHeaders) {
      return keyHeaders.map(key => {
        const field = listOptions.fields[key]
        return format(key, obj, field, withFormatPassword) || ''
      })
    }
    if (convertFormatAndDates) {
      return Object.keys(obj).map(key => {
        const field = listOptions.fields[key]
        return format(key, obj, field, withFormatPassword)
      })
    }
    return Object.values(obj)
  })
  let header
  if (keyHeaders) {
    header = keyHeaders.map(key => {
      const field = listOptions.fields[key]
      return field ? field.label : key
    })
  } else {
    header = Object.keys(list[0]).map(key => {
      const field = listOptions.fields[key]
      return field ? field.label : key
    })
  }

  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet('Relatório')
  worksheet.columns = header.map(header => ({
    header,
    key: header
  }))
  worksheet.addRows(rows)
  const buf = await workbook.xlsx.writeBuffer()
  const date = formatDateTime(new Date())
  saveAs(new Blob([buf]), `${name} - ${date}.xls`)
}

export const downloadXlsx = async ({
  list = [],
  listOptions,
  flatOnDownload = false,
  convertFormatAndDates = false,
  withFormatPassword = true,
  name = '',
  keyHeaders
}) => {
  if (list.length) {
    list.forEach(o => delete o.deletedAt)
    await createXlsx(
      list,
      listOptions,
      flatOnDownload,
      convertFormatAndDates,
      withFormatPassword,
      name,
      keyHeaders
    )
  }
}

import Grid from '@material-ui/core/Grid'
import { Autocomplete, DatePicker, Filter, List, ListApi } from 'elentari'
import { TextField } from 'final-form-material-ui'
import React, { Fragment } from 'react'
import { Field } from 'react-final-form'
import { caminhaoApi } from '../../services/Api'
import { loadClientes } from '../Clientes/Clientes'
import { loadRegrasFila } from '../RegrasFila'
import { loadUsers } from '../Usuarios/Usuarios'
import { DownloadList } from './DownloadList'
import { formatValues } from './utils/formatValues'
import { transformWhere } from './utils/transformWhere'
import { filtersConfig } from './utils/filtersConfig'

const CaminhoesFilter = () => {
  return (
    <Filter
      labels={{ find: 'Buscar', clear: 'Limpar' }}
      formatFilters={{
        id_regra_fila: value => value?.id,
        id_usuario: value => value?.id,
        id_cliente: value => value?.id
      }}
    >
      <Grid spacing={2} container>
        <Grid item xs={12} md={6}>
          <Field
            fullWidth
            component={Autocomplete}
            loadOptions={loadRegrasFila}
            label='Regra'
            name='id_regra_fila'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            fullWidth
            component={Autocomplete}
            loadOptions={loadUsers}
            label='Usuário'
            name='id_usuario'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field fullWidth component={TextField} label='Placa' name='placa' />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field fullWidth component={TextField} label='Senha' name='senha' />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            fullWidth
            component={Autocomplete}
            loadOptions={loadClientes}
            label='Cliente'
            name='id_cliente'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data Entrada De'
            name='data_entrada_de'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data Entrada Até'
            name='data_entrada_ate'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data Saída De'
            name='data_saida_de'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={DatePicker}
            label='Data Saída Até'
            name='data_saida_ate'
          />
        </Grid>
        <Grid item xs={12} container justify='flex-end'>
          <DownloadList />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function CaminhoesList (props) {
  const { realm } = JSON.parse(localStorage.user)

  const listOptions = {
    defaultOrder: 'data_entrada DESC',
    fields: {
      id: {
        label: 'Id'
      },
      usuario: {
        label: 'Usuário',
        noOrder: true
      },
      placa: {
        label: 'Placa'
      },
      cliente: {
        label: 'Cliente',
        noOrder: true
      },
      senha: {
        label: 'Senha'
      },
      data_entrada: {
        label: 'Data Entrada'
      },
      data_saida: {
        label: 'Data Saída'
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    const response = await formatValues(items)
    return response
  }

  const getCountDecorator = getCount => async ({ filter, location }) => {
    const copy = transformWhere(filter)
    return getCount({
      filter: {
        ...copy
      },
      location
    })
  }

  const onClickEdit =
    realm !== 'usuario'
      ? item => props.history.push(`/caminhoes/${item.id}`)
      : undefined

  return (
    <ListApi
      filtersConfig={filtersConfig}
      transformWhere={transformWhere}
      api={caminhaoApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount }) => (
        <Fragment>
          <CaminhoesFilter />
          <List
            {...props}
            withPaper
            onClickEdit={onClickEdit}
            getCount={getCountDecorator(getCount)}
            getPage={getPage}
            listOptions={listOptions}
            hideDownloadIcon
          />
        </Fragment>
      )}
    </ListApi>
  )
}

import Grid from '@material-ui/core/Grid'
import {
  Autocomplete,
  Filter,
  List,
  ListApi,
  loopback,
  Snackbar
} from 'elentari'
import { TextField } from 'final-form-material-ui'
import formatString from 'format-string-by-pattern'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { caixaApi, caminhaoEspecialApi, destinoApi } from '../../services/Api'
import { loadDestinos } from '../Destinos'

const { loopbackFilters } = loopback

const formatPlaca = anyString => {
  return formatString('XXX-XXXX')(anyString.toUpperCase())
}
function CaminhoesEspeciaisFilter (props) {
  return (
    <Filter
      formatFilters={{
        id_patio: value => value?.id,
        id_destino: value => value?.id
      }}
      labels={{ find: 'Buscar', clear: 'Limpar' }}
    >
      <Grid spacing={3} container>
        <Grid item xs={4}>
          <Field
            fullWidth
            component={TextField}
            parse={formatPlaca}
            label='Placa'
            name='placa'
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label='Destino'
            loadOptions={loadDestinos}
            component={Autocomplete}
            name='id_destino'
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            fullWidth
            component={TextField}
            label='Observação'
            name='observacao'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function CaminhaoEspecialList (props) {
  const [caixa, setCaixa] = useState()
  const [mensagem, setMensagem] = useState()
  const fetch = async () => {
    const result = await caixaApi.findOne({
      filter: {
        where: {
          data_fechamento: { eq: null }
        }
      }
    })
    if (result.ok) {
      setCaixa(result.data.id)
    }
    if (result.status === 404) {
      setMensagem('Não foi possível acessar o caixa.')
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const handleSanckbarClose = () => {
    setMensagem('')
  }

  const listOptions = {
    defaultOrder: 'data_entrada',
    fields: {
      data_entrada: {
        label: 'Ordem de entrada',
        format: date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')
      },
      placa: {
        label: 'Placa'
      },
      destino: {
        label: 'Destino'
      },
      numero_eixo: {
        label: 'Eixos'
      },
      observacao: {
        label: 'Observação'
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    if (items) {
      const promises = items.map(async itemD => {
        const responseDestino = await destinoApi.getOne(itemD.id_destino)
        if (responseDestino.ok) {
          itemD.destino = responseDestino.data.descricao
        }
        return itemD
      })
      return Promise.all(promises)
    }
  }

  const onClickRow = item => {
    const params = `caminhaoId=${item.id}&placa=${item.placa}&destinoId=${
      item.id_destino
    }&destinoNome=${item.destino}&numeroEixo=${item.numero_eixo}`
    props.history.push(`/caixas/${caixa}/operacoes-caixa/new?${params}`)
  }

  return (
    <ListApi
      filtersConfig={{
        placa: loopbackFilters.ilike,
        observacao: loopbackFilters.ilike
      }}
      api={caminhaoEspecialApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount, handleDeleteF, getCaixaId }) => (
        <div>
          <CaminhoesEspeciaisFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            DoneIcon
            deleteFItem={handleDeleteF}
            onClickEdit={item =>
              props.history.push(`/caminhoes-irregulares/${item.id}`)
            }
            onClickRow={onClickRow}
            getPage={getPage}
            getCaixaId={getCaixaId}
            getCount={getCount}
            listOptions={listOptions}
          />
          <Snackbar
            autoHideDuration={6000}
            onClose={handleSanckbarClose}
            message={mensagem}
          />
        </div>
      )}
    </ListApi>
  )
}

import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#FF6F5E'
    },
    secondary: {
      main: '#F5D6B6'
    }
  }
})

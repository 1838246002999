import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { TextField } from 'final-form-material-ui'
import * as R from 'ramda'
import React from 'react'
import { Field, Form } from 'react-final-form'
import CurrencyTextField from '../Fields/Currency'

export default function SimpleFormDialog (props) {
  const {
    open = false,
    handleClose = R.empty,
    primaryAction = (first, second) => R.empty(),
    defaultDescricao = '',
    title = ''
  } = props
  const { realm } = JSON.parse(localStorage.user)

  const handlePrimaryAction = data => {
    primaryAction(data)
    handleClose()
  }

  const validate = values => ({
    valor: values.valor ? undefined : 'Obrigatório'
  })

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handlePrimaryAction}
            initialValues={{ descricao: defaultDescricao, valor: null }}
            validate={validate}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  direction='row'
                  justify='flex-start'
                  alignItems='center'
                >
                  <Grid item sm={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label='Descrição'
                      name='descricao'
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      fullWidth
                      component={CurrencyTextField}
                      minimumValue='0'
                      label='Valor'
                      name='valor'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid justify='flex-end' container spacing={2}>
                      <Grid item>
                        <Button fullWidth onClick={handleClose}>
                          Voltar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          type='submit'
                          disabled={submitting}
                          variant='contained'
                          color='primary'
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const opcaoPagamento = {
  FATURAMENTO: 'faturamento',
  PAGAMENTO_VISTA: 'pagamento_vista'
}

export const formatOpcaoPagamento = opcao_pagamento => {
  switch (opcao_pagamento) {
    case opcaoPagamento.FATURAMENTO: {
      return 'Faturamento'
    }
    case opcaoPagamento.PAGAMENTO_VISTA: {
      return 'Pagamento à Vista'
    }
    default: {
      return 'N/A'
    }
  }
}

import { create } from 'apisauce'
import { apiCrudFactory } from 'elentari'
import config from '../Config'

const baseURL = config.apiUrl

const AUTH_HEADER = 'Authorization'

const api = create({
  baseURL: baseURL
})

const impressora = create({
  baseURL: 'http://localhost:5000'
})

const defaultFilter = {
  where: {
    removed: false
  }
}

const CACHE_NAME = 'patio-cache'

const networkFallbackCache = async response => {
  const request = new Request(
    response.config.url + JSON.stringify(response.config.params)
  )
  const cache = await window.caches?.open(CACHE_NAME)
  if (!cache) {
    return response
  }
  if (navigator.onLine) {
    await cache.put(request, new Response(JSON.stringify(response.data)))
    return response
  } else {
    const res = await cache.match(request)
    const json = await res.json()
    return { ok: true, data: json }
  }
}

const createCrud = apiCrudFactory({ api, defaultFilter })

export const produtoApi = {
  ...createCrud('Produtos'),
  chamada: filtro => api.get(`Produtos/chamada`, filtro),
  remove: id => api.delete(`Produtos/${id}`)
}
export const origemApi = {
  ...createCrud('Origens'),
  remove: id => api.delete(`Origens/${id}`)
}
export const transportadoraApi = {
  ...createCrud('Transportadoras'),
  remove: id => api.delete(`Transportadoras/${id}`)
}
export const destinoApi = {
  ...createCrud('Destinos'),
  getList: async filter =>
    networkFallbackCache(await createCrud('Destinos').getList(filter)),
  chamada: filtro => api.get(`Destinos/chamada`, filtro),
  remove: id => api.delete(`Destinos/${id}`)
}
export const clienteApi = {
  ...createCrud('Clientes'),
  remove: id => api.delete(`Clientes/${id}`)
}

export const tabelaPrecoApi = {
  ...createCrud('Tabela_precos'),
  remove: id => api.delete(`Tabela_precos/${id}`)
}

export const filaApi = {
  ...createCrud('Filas'),
  getOne: async (id, filter) =>
    networkFallbackCache(await createCrud('Filas').getOne(id, filter)),
  painel: async filtro =>
    networkFallbackCache(await api.get(`Filas/painel`, filtro)),
  filasDisponiveis: query => api.get('Filas/filasDisponiveis', query),
  remove: id => api.delete(`Filas/${id}`)
}

export const regraFilaApi = {
  ...createCrud('Regras_fila'),
  getOne: async (id, filter) =>
    networkFallbackCache(await createCrud('Regras_fila').getOne(id, filter)),
  chamada: filtro => api.get(`Regras_fila/chamada`, filtro),
  remove: id => api.delete(`Regras_fila/${id}`)
}

export const tipoFreteApi = {
  ...createCrud('Tipos_frete'),
  remove: id => api.delete(`Tipos_frete/${id}`)
}
export const patioApi = {
  ...createCrud('Patios'),
  getList: async filter =>
    networkFallbackCache(await createCrud('Patios').getList(filter)),
  remove: id => api.delete(`Patios/${id}`)
}
export const caixaApi = {
  ...createCrud('Caixas'),
  findOne: filter => api.get('Caixas/findOne', filter),
  fechar: (id, info) => api.post(`Caixas/fechar/${id}`, { info })
}
export const caminhaoApi = {
  ...createCrud('Caminhoes'),
  listaChamada: async filtro =>
    networkFallbackCache(await api.get(`Caminhoes/listaChamada`, filtro)),
  iniciarChamada: ids => api.post(`Caminhoes/iniciarChamada`, ids),
  cancelarChamada: id => api.post(`Caminhoes/cancelarChamada/${id}`),
  confirmarChamada: id => api.post(`Caminhoes/confirmarChamada/${id}`),
  saidaEspecial: (id, body) => api.post(`Caminhoes/saidaEspecial/${id}`, body),
  cancelarSenha: (id, body) => api.post(`Caminhoes/cancelarSenha/${id}`, body),
  alterarFila: (id, body) => api.post(`Caminhoes/alterarFila/${id}`, body),
  registrarEntrada: body => api.post('Caminhoes/registrarEntrada', body),
  registrarEntradaAutorizadaAdmin: body =>
    api.post('Caminhoes/registrarEntradaAutorizadaAdmin', body),
  countByOrigemProduto: () => api.get('Caminhoes/countByOrigemProduto'),
  getImagemEixo: path => api.get(`Caminhoes/imagem/${path}`),
  getUrlStream: () => `${baseURL}Caminhoes/change-stream?_format=event-stream`
}

export const caminhaoEspecialApi = {
  ...createCrud('Caminhoes_especiais')
}
export const operacaoCaixaApi = {
  ...createCrud('Operacoes_caixa'),
  sangria: body => api.post('Operacoes_caixa/sangria', body),
  suprimento: body => api.post('Operacoes_caixa/suprimento', body),
  estorno: body => api.post('Operacoes_caixa/estorno', body),
  sumValor: query => api.get('Operacoes_caixa/sumValor', query),
  sumValorGrouped: query => api.get('Operacoes_caixa/sumValorGrouped', query),
  countGrouped: query => api.get('Operacoes_caixa/countGrouped', query),
  enviarRelatorioFaturamentoPorCliente: query =>
    api.get('Operacoes_caixa/enviarRelatorioFaturamentoPorCliente', query)
}
export const usuarioApi = {
  ...createCrud('Usuarios'),
  customLogin: credentials => api.post('Usuarios/customLogin', credentials),
  authenticated: () => api.post('Usuarios/authenticated')
}
export const contatoApi = {
  ...createCrud('Contatos'),
  remove: id => api.delete(`Contatos/${id}`)
}
export const templateApi = {
  getOne: id => api.get(`/Templates/${id}`)
}
export const logReimpressaoApi = createCrud('Log_reimpressoes')
export const impressoraApi = {
  imprimir: body => impressora.post('/imprimir', body)
}

export const setToken = token => api.setHeader(AUTH_HEADER, token)

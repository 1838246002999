import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import {
  loopback,
  ListApi,
  List,
  Filter,
  Select,
  autocompleteHelpers
} from 'elentari'
import { tabelaPrecoApi } from '../../services/Api'
import { customHandleDeleteF } from '../../utils/elentari'
const { loopbackFilters } = loopback
const { toOption } = autocompleteHelpers

export const loadTabelaPrecos = async inputValue => {
  const response = await tabelaPrecoApi.getList({
    where: {
      descricao: loopbackFilters.ilike(inputValue),
      ativo: true
    },
    order: 'descricao asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('descricao'))
  }
  return []
}

function TabelaPrecosFilter (props) {
  return (
    <Filter
      formatFilters={{ id_tabela_preco: value => value.id }}
      labels={{ find: 'Buscar', clear: 'Limpar' }}
    >
      <Grid spacing={3} container>
        <Grid item sm={8}>
          <Field
            fullWidth
            component={TextField}
            label='Descrição'
            name='descricao'
          />
        </Grid>
        <Grid item sm={4}>
          <Field
            fullWidth
            hideEmpty
            component={Select}
            options={[
              { label: 'Ativo', value: true },
              { label: 'Inativo', value: false }
            ]}
            label='Status'
            name='ativo'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function TabelaPrecoList (props) {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      descricao: {
        label: 'Descrição'
      },
      ativo: {
        label: 'Status',
        format: boolean => (boolean ? 'Ativo' : 'Inativo')
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    return items
  }

  return (
    <ListApi
      filtersConfig={{
        descricao: loopbackFilters.ilike
      }}
      api={tabelaPrecoApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount }) => (
        <div>
          <TabelaPrecosFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            deleteFItem={customHandleDeleteF(tabelaPrecoApi)}
            onClickEdit={item =>
              props.history.push(`/tabelas-precos/${item.id}`)
            }
            onClickNew={() => props.history.push('/tabelas-precos/new')}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApi>
  )
}

import React from 'react'
import secureLocalStorage from 'react-secure-storage'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { caminhaoEspecialApi, destinoApi } from '../../services/Api'
import { loadDestinos } from '../Destinos'
import {
  FormApi,
  CrudForm,
  Autocomplete,
  autocompleteHelpers,
  MountEntity
} from 'elentari'
import { parsePlaca } from '../../utils/parse'
import { formatEixo } from '../../utils/format'
const { toOption } = autocompleteHelpers

export function CaminhaoEspecialForm (props) {
  const validate = values => ({
    placa: values.placa && values.placa.trim() ? undefined : 'Obrigatório',
    numero_eixo: values.numero_eixo ? undefined : 'Obrigatório',
    destino: values.destino ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const guarita = secureLocalStorage.getItem('guarita')

    const result = await handleSubmit({
      id,
      data: {
        ...data,
        id_destino: data.destino.value,
        guarita
      }
    })
    return result
  }

  const getItemDecorator = async ({ getItem, filter }) => {
    if (props.match.params.id === 'new') {
      return getItem(filter)
    }
    const result = await MountEntity(props, caminhaoEspecialApi.getOne)
      .populate(({ data, id }) => ({
        destino: destinoApi.getOne(data.id_destino)
      }))
      .clean(['id_destino', 'id_patio'])
      .map(data => ({
        ...data,
        destino: toOption('descricao')(data.destino)
      }))
      .getItem()

    return result
  }

  return (
    <FormApi
      initialData={{ data_entrada: Date.now() }}
      api={caminhaoEspecialApi}
      submitDecorator={submitDecorator}
      getItemDecorator={getItemDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                component={TextField}
                label='Placa'
                name='placa'
                parse={parsePlaca}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                component={TextField}
                label='Número de eixos'
                name='numero_eixo'
                type='number'
                parse={formatEixo}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                component={Autocomplete}
                loadOptions={loadDestinos}
                label='Destino'
                name='destino'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={TextField}
                label='Observação'
                name='observacao'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { usuarioApi } from '../../services/Api'
import {
  loopback,
  ListApi,
  List,
  Filter,
  Select,
  Snackbar,
  autocompleteHelpers
} from 'elentari'
const { toOption } = autocompleteHelpers
const { loopbackFilters } = loopback

export const loadUsers = async inputValue => {
  const response = await usuarioApi.getList({
    where: {
      email: loopbackFilters.ilike(inputValue)
    },
    order: 'username asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('username'))
  }
  return []
}

function UsuariosFilter (props) {
  return (
    <Filter labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid spacing={3} container>
        <Grid item xs={12} sm={4}>
          <Field fullWidth component={TextField} label='Nome' name='username' />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field fullWidth component={TextField} label='E-mail' name='email' />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            hideEmpty
            component={Select}
            options={[
              { label: 'Administrador', value: 'admin' },
              { label: 'Gestor', value: 'gestor' },
              { label: 'Operador', value: 'operador' },
              { label: 'Destino', value: 'destino' },
              { label: 'Usuário', value: 'usuario' }
            ]}
            label='Permissão'
            name='realm'
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function UsuariosList (props) {
  const [snackbar, setSnackbar] = useState('')

  const formatRealm = realm => {
    if (realm === 'admin') {
      return 'Administrador'
    }
    if (realm === 'gestor') {
      return 'Gestor'
    }
    if (realm === 'operador') {
      return 'Operador'
    }
    if (realm === 'destino') {
      return 'Destino'
    }
    if (realm === 'usuario') {
      return 'Usuário'
    }
  }
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      username: {
        label: 'Nome'
      },
      email: {
        label: 'E-mail'
      },
      realm: {
        label: 'Permissão',
        format: realm => formatRealm(realm)
      }
    }
  }

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage()
    // Para não quebrar o List em caso do sistema offline
    if (items.ok === false) return []
    return items
  }

  return (
    <ListApi
      filtersConfig={{
        username: loopbackFilters.ilike,
        email: loopbackFilters.ilike
      }}
      api={usuarioApi}
      getPageDecorator={getPageDecorator}
    >
      {({ getPage, getCount, handleDelete, undo }) => (
        <div>
          <UsuariosFilter />
          <List
            {...props}
            hideDownloadIcon
            withPaper
            undo={undo}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
          <Snackbar
            message={snackbar}
            onClose={() => setSnackbar('')}
            autoHideDuration={4000}
          />
        </div>
      )}
    </ListApi>
  )
}

import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Filter, Select, Autocomplete, DatePicker } from 'elentari'
import { loadDestinos } from '../../Destinos'
import { loadClientes } from '../../Clientes'
import OperacoesCards from './OperacoesCards'
import ValoresEntradaChart from './ValoresEntradaChart'
import QuantidadeEntradaChart from './QuantidadeEntradaChart'
import moment from 'moment'
import { Button } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { makeStyles } from '@material-ui/core/styles'
import { getURLFilter, transformFilter } from './FilterUtils'
import { operacaoCaixaApi } from '../../../services/Api'
import { Snackbar } from 'elentari'

const ConditionalField = ({ when, is, children }) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  )
}

const WhenFieldChanges = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <OnChange name={field}>
        {value => {
          if (value === becomes) {
            onChange(to)
          }
        }}
      </OnChange>
    )}
  </Field>
)

const FinanceiroFilter = props => {
  const useStyles = makeStyles(theme => ({
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    btn: {
      float: 'right',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  }))

  const classes = useStyles()

  const search = window.location.search

  const handleInitialValues = () => ({
    visualizacao: 'diario',
    dia: moment().format('YYYY-MM-DD')
  })

  useEffect(() => {
    const filter = getURLFilter(search)

    const hasVisulizacao = Boolean(filter['visualizacao'])

    if (hasVisulizacao) return

    const defaultInitialValues = handleInitialValues()

    const params = [
      `visualizacao=${defaultInitialValues.visualizacao}`,
      `dia=${defaultInitialValues.dia}`
    ]

    const paramsUrl = new URLSearchParams(window.location.search)
    paramsUrl.append('filters', params.join(','))

    const newUrl = `${window.location.pathname}?${paramsUrl.toString()}`
    props.history.push(newUrl)
  }, [search])

  return (
    <Filter
      formatFilters={{
        id_destino: value => value && value.id,
        id_cliente: value => value && value.id,
        dia: date => moment(date).format('YYYY-MM-DD')
      }}
      initialValues={handleInitialValues()}
      labels={{ find: 'Mostrar', clear: 'Limpar' }}
    >
      <>
        <WhenFieldChanges
          field='visualizacao'
          becomes='diario'
          set='ano'
          to={undefined}
        />
        <WhenFieldChanges
          field='visualizacao'
          becomes='diario'
          set='mes'
          to={undefined}
        />
        <WhenFieldChanges
          field='visualizacao'
          becomes='diario'
          set='dia'
          to={new Date()}
        />

        <WhenFieldChanges
          field='visualizacao'
          becomes='mensal'
          set='ano'
          to={undefined}
        />
        <WhenFieldChanges
          field='visualizacao'
          becomes='mensal'
          set='dia'
          to={undefined}
        />
        <WhenFieldChanges
          field='visualizacao'
          becomes='mensal'
          set='mes'
          to={moment().format('YYYY-MM-01')}
        />

        <WhenFieldChanges
          field='visualizacao'
          becomes='anual'
          set='mes'
          to={undefined}
        />
        <WhenFieldChanges
          field='visualizacao'
          becomes='anual'
          set='dia'
          to={undefined}
        />
        <WhenFieldChanges
          field='visualizacao'
          becomes='anual'
          set='ano'
          to={moment().format('YYYY-01-01')}
        />
        <Grid spacing={3} container>
          <Grid item xs={3}>
            <Field
              fullWidth
              component={Autocomplete}
              loadOptions={loadDestinos}
              label='Destinos'
              name='id_destino'
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fullWidth
              component={Autocomplete}
              loadOptions={loadClientes}
              label='Clientes'
              name='id_cliente'
            />
          </Grid>
          <Grid item sm={3}>
            <Field
              fullWidth
              hideEmpty
              component={Select}
              options={[
                { label: 'Diário', value: 'diario' },
                { label: 'Mensal', value: 'mensal' },
                { label: 'Anual', value: 'anual' }
              ]}
              label='Visualização'
              name='visualizacao'
            />
          </Grid>
          <ConditionalField when='visualizacao' is='anual'>
            <Grid item sm={3}>
              <Field
                fullWidth
                hideEmpty
                component={Select}
                options={[...Array(5).keys()].map(n => {
                  return {
                    label: moment()
                      .subtract(n, 'years')
                      .format('YYYY'),
                    value: moment()
                      .subtract(n, 'years')
                      .format('YYYY-01-01')
                  }
                })}
                label='Ano'
                name='ano'
              />
            </Grid>
          </ConditionalField>
          <ConditionalField when='visualizacao' is='mensal'>
            <Grid item sm={3}>
              <Field
                fullWidth
                hideEmpty
                component={Select}
                options={[...Array(5 * 12).keys()].map(n => ({
                  label: moment()
                    .subtract(n, 'months')
                    .format('MM/YY'),
                  value: moment()
                    .subtract(n, 'months')
                    .format('YYYY-MM-01')
                }))}
                label='Mês'
                name='mes'
              />
            </Grid>
          </ConditionalField>
          <ConditionalField when='visualizacao' is='diario'>
            <Grid item sm={3}>
              <Field fullWidth component={DatePicker} label='Dia' name='dia' />
            </Grid>
          </ConditionalField>
        </Grid>
        <Button
          className={classes.btn}
          variant='extended'
          onClick={props.handleEnvioRelatorio}
        >
          <MailOutlineIcon className={classes.extendedIcon} /> Enviar relatório
          faturamento
        </Button>
      </>
    </Filter>
  )
}

const Financeiro = props => {
  const [snackbar, setSnackbar] = useState('')

  const handleEnvioRelatorio = async () => {
    const search = props.history.location.search
    const filter = getURLFilter(search)
    const data = transformFilter(filter)
    const response = await operacaoCaixaApi.enviarRelatorioFaturamentoPorCliente(
      data
    )
    if (response.ok) {
      setSnackbar('Relatório de faturamento enviado')
    } else {
      setSnackbar('Ops! Ocorreu um erro ao enviar o relatório')
    }
  }

  return (
    <div>
      <FinanceiroFilter
        {...props}
        handleEnvioRelatorio={handleEnvioRelatorio}
      />
      <Snackbar
        autoHideDuration={4000}
        onClose={() => {
          setSnackbar('')
        }}
        message={snackbar}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OperacoesCards {...props} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ValoresEntradaChart {...props} />
            </Grid>
            <Grid item xs={12}>
              <QuantidadeEntradaChart {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Financeiro

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import QuantidadeCaminhoesCard from './QuantidadeCaminhoesCard'
import TempoCaminhoesCard from './TempoCaminhoesCard'
import UltimosChamados from './UltimosChamados'


const styles = theme => ({
  marginTop: {
    marginTop: theme.spacing(3)
  },
})

const Patio = ({classes, ...props}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <QuantidadeCaminhoesCard />
        <div className={classes.marginTop}>
          <UltimosChamados {...props} />
        </div>
      </Grid>
      <Grid item xs={6}>
        <TempoCaminhoesCard {...props} />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Patio)

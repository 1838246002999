import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import { Field, Form } from 'react-final-form'
import { TextField } from 'elentari'

export function ModalAdminCredentials ({ open, handleClose, primaryAction }) {
  const validate = values => ({
    usuario:
      values.usuario && values.usuario.trim() ? undefined : 'Obrigatório',
    senha: values.senha && values.senha.trim() ? undefined : 'Obrigatório'
  })

  const handlePrimaryAction = data => {
    primaryAction(data)
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>
        Informe as credenciais de um Admnistrador
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={handlePrimaryAction} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    fullWidth
                    style={{ textAlign: 'center' }}
                    color='textSecondary'
                  >
                    O transportador não é cadastrado na frota informada, insira
                    as credenciais de um administrador para prosseguir com o
                    cadastro.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    label='Usuário'
                    name='usuario'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    type='password'
                    autoComplete='new-password'
                    label='Senha'
                    name='senha'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid justify='flex-end' container spacing={2}>
                    <Grid item>
                      <Button fullWidth onClick={handleClose}>
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        type='submit'
                        disabled={submitting}
                        variant='contained'
                        color='primary'
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  )
}

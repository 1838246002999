import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FilaCard from '../Filas/FilaCard'
import { compose } from 'recompose'

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 16
  },
  patio: {
    overflowX: 'auto'
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  }
})

function PatioCard ({ classes, patio, onCloseFila, onOpenFila, ...props }) {
  const [open, setOpen] = useState(true)

  const getGridListCols = () => {
    if (isWidthUp('xl', props.width)) {
      return 12
    }
    if (isWidthUp('lg', props.width)) {
      return 8
    }
    return 4
  }

  const handleClick = () => {
    setOpen(!open)
  }

  const renderFilas = filas => {
    return filas.map(fila => (
      <GridListTile key={fila.id}>
        <FilaCard
          {...props}
          fila={fila}
          onClose={onCloseFila}
          onOpen={onOpenFila}
        />
      </GridListTile>
    ))
  }

  return (
    <Grid container direction='row' justify='flex-start' alignItems='center'>
      <Grid item sm={12}>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid item>{patio.descricao}</Grid>
          <Grid item sm={10}>
            <Divider />
          </Grid>
          <Grid item>
            <IconButton onClick={handleClick}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <GridList
            className={classes.gridList}
            cellHeight='auto'
            cols={getGridListCols()}
          >
            {renderFilas(patio.filas)}
          </GridList>
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default compose(
  withWidth(),
  withStyles(styles)
)(PatioCard)

import Grid from '@material-ui/core/Grid'
import { CrudForm, DateTimePicker, FormApi } from 'elentari'
import { TextField } from 'final-form-material-ui'
import moment from 'moment'
import React from 'react'
import { Field } from 'react-final-form'
import { caixaApi } from '../../services/Api'
import { imprimirAberturaCaixa } from '../../utils/impressora'
import { realToCentavos } from '../../utils/monetary'
import CurrencyTextField from '../Fields/Currency'

export function CaixaForm (props) {
  const user = JSON.parse(localStorage.user)

  const validate = values => ({
    nome: values.nome ? undefined : 'Obrigatório',
    data_abertura: values.data_abertura ? undefined : 'Obrigatório',
    valor_abertura: values.valor_abertura ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        valor_abertura: realToCentavos(data.valor_abertura)
      }
    })
    if (result.ok) {
      const impressora = await imprimirAberturaCaixa(data)
      return impressora
    }
    return result
  }

  return (
    <FormApi
      initialData={{
        nome: `Caixa ${moment(new Date()).format('DD/MM/YY')}: ${user.name}`,
        data_abertura: new Date()
      }}
      api={caixaApi}
      submitDecorator={submitDecorator}
      {...props}
    >
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Field fullWidth component={TextField} label='Nome' name='nome' />
            </Grid>
            <Grid item sm={4}>
              <Field
                fullWidth
                disabled
                component={DateTimePicker}
                label='Data de abertura'
                name='data_abertura'
              />
            </Grid>
            <Grid item sm={4}>
              <Field
                fullWidth
                component={CurrencyTextField}
                minimumValue='0'
                label='Valor de abertura'
                name='valor_abertura'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

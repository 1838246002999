import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import { withStyles, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { caminhaoApi } from '../../../services/Api'

const styles = theme => ({
  primeiro: {
    textAlign: 'center'
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(2)
  }
})

function UltimosChamados({ classes, ...props }) {
  const [caminhoes, setCaminhoes] = useState([])

  useEffect(() => {
    async function fetchUltimos() {
      const filter = {
        where: {
          and: [
            {data_saida: { neq: null }},
            {data_cancelamento: { eq: null }},
          ]
        },
        order: 'data_saida DESC',
        limit: 5
      }
      const response = await caminhaoApi.getList(filter)
      if (response.ok) {
        setCaminhoes(response.data)
      }
    }
    fetchUltimos()
    let isSubscribed = true
    const urlToChangeStream = caminhaoApi.getUrlStream()
    const src = new EventSource(urlToChangeStream)
    if (isSubscribed) {
      src.addEventListener('data', function() {
        fetchUltimos()
      })
    }
    return () => {
      isSubscribed = false
      src.close()
    }
  }, [props.history.location])

  const renderOutros = () => {
    return caminhoes.slice(1).map((caminhao, index) => {
      const data = moment(caminhao.data_saida).format('DD/MM/YYYY HH:mm:ss')
      return (
        <React.Fragment key={caminhao.id}>
          <Typography variant='body2' gutterBottom>
            {`${index+2}º: ${caminhao.senha} - ${data}`}
          </Typography>
        </React.Fragment>
    )})
  }

  return (
    <Card className={classes.card}>
      <Typography variant='subtitle1' align='center'>
        Últimos chamados
      </Typography>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='center'>
        {caminhoes.length
          ? (<React.Fragment>
            <Grid item sm={6} className={classes.primeiro}>
              <Typography variant='h4' gutterBottom color='primary'>
                {caminhoes[0].senha}
              </Typography>
              <Typography variant='body2' gutterBottom>
                {moment(caminhoes[0].data_saida).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              {renderOutros()}
            </Grid>
          </React.Fragment>)
          : (<Typography variant='h6' gutterBottom color='primary'>
            Nenhum Caminhão chamado
          </Typography>)
        }
      </Grid>
    </Card>
  )
}

export default withStyles(styles)(UltimosChamados)

import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withStyles, Button, Typography, Grid } from '@material-ui/core'
import { TextField } from 'final-form-material-ui'
import { Field, Form } from 'react-final-form'
import { regraFilaApi } from '../../services/Api'

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  field: {
    marginBottom: theme.spacing(2)
  }
})

const RegraFilaDialog = props => {
  const { open, handleClose, origem, produto, destino, classes } = props
  const [submitError, setSubmitError] = useState({ error: false, message: '' })

  const handleSubmit = async ({ sigla, numero_senha }) => {
    const response = await regraFilaApi.create({
      sigla,
      numero_senha,
      id_origem: origem.value,
      id_produto: produto.value,
      id_destino: destino.value
    })
    if (response.ok) {
      handleClose()
    } else {
      setSubmitError({
        error: true,
        message: response.data.error.message
      })
    }
  }

  const validate = values => ({
    sigla: values.sigla ? undefined : 'Obrigatório'
  })

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={classes.root}>
        <Typography variant='h4' gutterBottom>
          Criar nova regra
        </Typography>
        <Typography variant='body1'>
          Não existe uma regra para o seguinte conjunto:
        </Typography>
        <Typography variant='body1'>
          Destino: {destino && destino.label}, Origem: {origem && origem.label},
          Produto: {produto && produto.label}
        </Typography>
        <Form onSubmit={handleSubmit} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                fullWidth
                component={TextField}
                label='Sigla'
                name='sigla'
                className={classes.field}
              />
              <Field
                fullWidth
                component={TextField}
                label='Senha'
                name='numero_senha'
                type='number'
                parse={number => (number < 0 ? 0 : number)}
                className={classes.field}
              />
              <Grid justify='flex-end' container spacing={2}>
                <Grid item>
                  <Button fullWidth onClick={handleClose}>
                    Voltar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    type='submit'
                    disabled={submitting}
                    variant='contained'
                    color='primary'
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
              {submitError.error && (
                <Typography color='error'>
                  Erro ao criar nova regra: {submitError.message}
                </Typography>
              )}
            </form>
          )}
        </Form>
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(RegraFilaDialog)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { patioApi } from '../../services/Api'
import { FormApi, CrudForm } from 'elentari'

export function PatioForm (props) {
  const validate = values => ({
    descricao: (values.descricao && values.descricao.trim()) ? undefined : 'Obrigatório'
  })

  return (
    <FormApi api={patioApi} {...props}>
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={TextField}
                label='Descrição'
                name='descricao'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { contatoApi } from '../../services/Api'
import { FormApi, CrudForm, Checkbox } from 'elentari'

export function ContatoForm (props) {
  const validate = values => ({
    email: (values.email && values.email.trim()) ? undefined : 'Obrigatório',
  })

  return (
    <FormApi
      api={contatoApi}
      {...props}>
      {({ handleSubmit, getItem }) => (
        <CrudForm
          withPaper
          validate={validate}
          history={props.history}
          getItem={getItem}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                component={TextField}
                label='E-mail'
                name='email'
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Caixa'
                name='caixa'
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <Field
                fullWidth
                component={Checkbox}
                type='checkbox'
                label='Financeiro'
                name='financeiro'
              />
            </Grid>
          </Grid>
        </CrudForm>
      )}
    </FormApi>
  )
}

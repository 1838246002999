import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Form } from 'react-final-form'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import moment from 'moment'
import { regraFilaApi, caminhaoApi, filaApi } from '../../services/Api'

const styles = theme => ({
  ordem: {
    fontWeight: 'bold'
  },
  row: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1)
  }
})

class ListaCaminhoes extends React.Component {
  state = {
    selecteds: [],
    fetching: false,
    caminhoes: [],
    page: 1,
    rows: 20,
    more: true
  }

  componentDidMount () {
    this.fetchCaminhoes()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.filtro !== prevProps.filtro) {
      this.setState(
        {
          page: 1,
          caminhoes: [],
          selecteds: []
        },
        () => this.fetchCaminhoes()
      )
    }
  }

  async refetchCaminhoes () {
    this.setState(
      {
        page: 1,
        caminhoes: [],
        selecteds: []
      },
      () => this.fetchCaminhoes()
    )
  }

  async fetchCaminhoes () {
    this.setState({ fetching: true })
    this.props.setLoading(true)
    const { filtro } = this.props
    const { page, rows } = this.state
    const response = await caminhaoApi.listaChamada({
      filtro: { ...filtro, page, rows }
    })
    if (response.ok) {
      const promises = response.data.map(async item => {
        const filter = { include: ['destino', 'produto'] }
        const [responseRegra, responseFila] = await Promise.all([
          regraFilaApi.getOne(item.id_regra_fila, filter),
          filaApi.getOne(item.id_fila)
        ])
        if (responseRegra.ok) {
          item.regraFila = responseRegra.data
        }
        if (responseFila.ok) {
          item.fila = responseFila.data
        }
        return item
      })
      const result = await Promise.all(promises)
      this.setState({
        caminhoes: this.state.caminhoes.concat(result),
        more: result.length === rows
      })
    }
    this.props.setLoading(false)
    this.setState({ fetching: false })
  }

  handleSubmit = async () => {
    const { selecteds } = this.state
    const response = await caminhaoApi.iniciarChamada(selecteds)
    if (response.ok) {
      this.props.history.push(`/chamada/iniciar?ids=${selecteds}`)
    }
  }

  handleClickRow = id => {
    let { selecteds } = this.state
    if (selecteds.indexOf(id) < 0) {
      selecteds = selecteds.concat(id)
    } else {
      selecteds = selecteds.filter(i => i !== id)
    }
    return this.setState({ selecteds })
  }

  handleMoreClick = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => this.fetchCaminhoes()
    )
  }

  renderCaminhoes = () => {
    const { classes } = this.props
    const { caminhoes, selecteds } = this.state
    return caminhoes.map((caminhao, index) => (
      <Grid
        key={caminhao.id}
        container
        component='table'
        direction='row'
        justify='flex-start'
        alignItems='center'
        className={classes.row}
        onClick={() => this.handleClickRow(caminhao.id)}
      >
        <Grid item xs={2} md={1} className={classes.ordem}>
          <Checkbox
            color='primary'
            checked={selecteds.indexOf(caminhao.id) >= 0}
            value={caminhao.id.toString()}
          />
          {`${index + 1}º`}
        </Grid>
        <Grid item xs={2} md={1}>
          {`Fila: ${caminhao.fila.numero}`}
        </Grid>
        <Grid item xs={4} md={3}>
          {caminhao.senha}
        </Grid>
        <Grid item xs={4} md={2}>
          {caminhao.placa}
        </Grid>
        <Grid item xs={6} md={3}>
          {`${caminhao.regraFila.destino.descricao} - ${
            caminhao.regraFila.produto.descricao
          }`}
        </Grid>
        <Grid item xs={6} md={2}>
          {moment(caminhao.data_entrada).format('DD/MM/YY - HH:mm:ss')}
        </Grid>
      </Grid>
    ))
  }

  render () {
    const { fetching, selecteds, more } = this.state
    return !fetching ? (
      <Form onSubmit={this.handleSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item xs={4}>
                <Typography variant='h5' gutterBottom>
                  Fila de Chamada
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  onClick={() => this.refetchCaminhoes()}
                  variant='contained'
                  color='secondary'
                >
                  Atualizar Lista
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  type='submit'
                  variant='contained'
                  disabled={!selecteds.length}
                  color='primary'
                >
                  Chamar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='center' alignItems='center'>
                  {this.renderCaminhoes()}
                  <div hidden={fetching || !more}>
                    <IconButton
                      aria-label='Mais'
                      onClick={() => this.handleMoreClick()}
                    >
                      <ArrowDownwardIcon fontSize='inherit' />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    ) : (
      <Grid container direction='row' justify='center' alignItems='center'>
        <CircularProgress />
      </Grid>
    )
  }
}

export default withStyles(styles)(ListaCaminhoes)
